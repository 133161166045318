import { useState } from 'react'
import { View } from 'react-native'
import styled from 'styled-components'
import { parseJSON } from 'date-fns'
import isAfter from 'date-fns/fp/isAfter'
import isBefore from 'date-fns/fp/isBefore'
import subWeeks from 'date-fns/fp/subWeeks'
import { Text } from '../../../../elements/typography'
import { media } from '../../../../style/media.js'
import { padZero, secondsToDuration } from '../../../../utils/secondsToDuration'
import { CountdownTimer } from '../../../common/CountdownTimer/CountdownTimer.js'

const PremieringIn = styled(Text)`
  font-family: ${(props) => props.theme.fontFamilies.baseBold};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${(props) => props.theme.fontSizes.small}px;
  ${media.md`
    font-size: ${(props) => props.theme.fontSizes.medium}px;
  `};
`

const LargeText = styled(Text)`
  font-size: ${(props) => props.theme.fontSizes.larger}px;
  ${media.sm`
    font-size: ${(props) => props.theme.fontSizes.xlarge}px;
  `};
  ${media.md`
    font-size: ${(props) => props.theme.fontSizes.xxlarge}px;
  `};
`

const PremiereNow = styled(LargeText)`
  margin-bottom: ${(props) => props.theme.spacing.sm}px;
`

const pluralizeDays = (days) => `day${days === 1 ? '' : 's'}`

export const PromoCountdown = ({ premiereFrom, premiereTo, liveType }) => {
  const now = new Date()

  const [isPremiering, setPremiering] = useState(isAfter(parseJSON(premiereFrom), now))
  const pastOrFuture =
    !premiereTo ||
    isAfter(parseJSON(premiereTo), now) ||
    isBefore(subWeeks(1, parseJSON(premiereTo)), now)

  if (pastOrFuture) {
    return null
  }

  return (
    <View>
      {!isPremiering && <PremieringIn>{liveType ? 'Live in' : 'Premiering in'}</PremieringIn>}
      {isPremiering ? (
        <PremiereNow>Premiering Now!</PremiereNow>
      ) : (
        <CountdownTimer date={premiereFrom} onZero={() => setPremiering(true)}>
          {(seconds) => {
            const { days, hrs, mins, secs } = secondsToDuration(seconds)
            return (
              <LargeText>
                {days} {pluralizeDays(days)} {hrs}:{padZero(mins)}:{padZero(secs)}
              </LargeText>
            )
          }}
        </CountdownTimer>
      )}
    </View>
  )
}
