import { useMemo, useState } from 'react'
import { getFestivalFilmStatus } from '../getters'

export const useFestivalCountdownStatus = (film) => {
  const [justStarted, setJustStarted] = useState(false)
  const [justEnded, setJustEnded] = useState(false)
  const {
    isLive: alreadyLive,
    isFinished: alreadyFinished,
    isLaterToday,
    isFuture,
  } = getFestivalFilmStatus(film)

  return useMemo(
    () => ({
      isLive: alreadyLive || justStarted,
      isFinished: alreadyFinished || justEnded,
      isFuture: isFuture && !justStarted,
      isLaterToday: isLaterToday && !justStarted,
      onZero: () => (alreadyLive ? setJustEnded(true) : isFuture && setJustStarted(true)),
    }),
    [alreadyFinished, alreadyLive, isFuture, isLaterToday, justEnded, justStarted],
  )
}
