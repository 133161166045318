import differenceInWeeks from 'date-fns/differenceInWeeks'
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import parseJSON from 'date-fns/parseJSON'
import { parseJustADate } from '../../utils/dates'
import { getFestivalFilmStatus } from '../festival/getters'

export const premieresThisWeek = ({ premiereFrom }) => {
  const from = parseJSON(premiereFrom)
  return isAfter(from, new Date()) && differenceInWeeks(from, new Date()) === 0
}

export const willPremiereSoon = ({ premiereFrom }) => {
  const from = parseJSON(premiereFrom)
  return isAfter(from, new Date()) && differenceInWeeks(from, new Date()) < 2
}

export const isPremiering = ({ premiereFrom, premiereTo }) =>
  (premiereFrom &&
    premiereTo &&
    isBefore(parseJSON(premiereFrom), new Date()) &&
    isAfter(parseJSON(premiereTo), new Date())) ||
  false

/**
 * Tests whether the film is viewable at all, which would be after its premiere
 * or release dates.
 */
export const isViewable = ({ premiereFrom, releaseDate, ...film }) => {
  const { isLive, isFestivalFilm } = getFestivalFilmStatus(film)
  return isFestivalFilm
    ? isLive
    : (premiereFrom && isBefore(parseJSON(premiereFrom), new Date())) ||
        (releaseDate && isBefore(parseJustADate(releaseDate), new Date())) ||
        false
}

export const mayViewByPaymentModel = (paymentModel, hasSubscription, isRented) =>
  (paymentModel?.tvod && isRented) ||
  (paymentModel?.svod && hasSubscription) ||
  paymentModel?.free ||
  (!paymentModel && hasSubscription)

/** Tests whether the film is TVOD premium plan and checks its rental availability. */
export const needsTVODPurchase = (film, isRented) => film?.paymentModel?.tvod && !isRented

/**
 * Tests whether a user has permission to watch a given film and verifies its availability.
 * If the user is offline and still has access to this film they can always watch until the
 * download expires.
 */
export const userMayView = (film, hasSubscription, isOffline = false, isRented) =>
  isOffline ||
  (film && isViewable(film) && mayViewByPaymentModel(film?.paymentModel, hasSubscription, isRented))
