import { View } from 'react-native'
import styled from 'styled-components'
import { Text } from '../../../../elements/typography'

const Value = styled(Text)`
  font-size: ${(props) => props.theme.fontSizes.xsmall}px;
  color: ${(props) => props.theme.colors.whiteFaded};
  width: 130px;
  margin-bottom: ${(props) => props.theme.spacing.xs}px;
`

const Row = styled(View)`
  flex-direction: row;
  margin-bottom: ${(props) => props.theme.spacing.sm}px;
`

const LeftSection = styled(Text)`
  width: 120px;
`

const CreditsBlock = ({ label, values }) => (
  <Row>
    <LeftSection>{label}</LeftSection>
    <View>
      {values.map((creditName) => (
        <Value key={creditName}>{creditName}</Value>
      ))}
    </View>
  </Row>
)

export const CreditsDesktop = ({ credits, ...props }) => (
  <>
    {credits.map((creditsType) => (
      <CreditsBlock
        key={creditsType.type}
        label={creditsType.label}
        values={creditsType.values}
        {...props}
      />
    ))}
  </>
)
