import PropTypes from 'prop-types'
import { IconButton } from '../../../../elements/controls/IconButton/IconButton.js'
import messages from '../../messages.js'

export const RecommendButton = ({ onPress, withLabel = true, ...props }) => (
  <IconButton
    {...props}
    name="share"
    intlMsg={withLabel && messages.recommendMovie}
    onPress={onPress}
  />
)

RecommendButton.propTypes = {
  onPress: PropTypes.func.isRequired,
}
