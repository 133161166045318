"use strict";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useFlixApiContext } from '../FlixApiContext';
import { CHECKOUT_INITIATED } from '../eventsNames';
export var CARD_PAYMENT_INTENT = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "CardPaymentIntentQuery"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "tierSlug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      },
      "directives": []
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "tierSku"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      },
      "directives": []
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "filmSlug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      },
      "directives": []
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "paymentIntentId"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "String"
        }
      },
      "directives": []
    }],
    "directives": [],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "paymentIntent"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "tierSlug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "tierSlug"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "tierSku"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "tierSku"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "filmSlug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "filmSlug"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "paymentIntentId"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "paymentIntentId"
            }
          }
        }],
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "rest"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "type"
            },
            "value": {
              "kind": "StringValue",
              "value": "PaymentIntent",
              "block": false
            }
          }, {
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "endpoint"
            },
            "value": {
              "kind": "StringValue",
              "value": "v2",
              "block": false
            }
          }, {
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "path"
            },
            "value": {
              "kind": "StringValue",
              "value": "tvod/stripe-intent?tierSlug={args.tierSlug}&tierSku={args.tierSku}&filmSlug={args.filmSlug}&paymentIntentId={args.paymentIntentId}",
              "block": false
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "clientSecret"
            },
            "arguments": [],
            "directives": []
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "paymentIntentId"
            },
            "arguments": [],
            "directives": []
          }]
        }
      }]
    }
  }],
  "loc": {
    "start": 0,
    "end": 547,
    "source": {
      "body": "\n  query CardPaymentIntentQuery(\n    $tierSlug: ID!\n    $tierSku: String!\n    $filmSlug: String!\n    $paymentIntentId: String\n  ) {\n    paymentIntent(\n      tierSlug: $tierSlug\n      tierSku: $tierSku\n      filmSlug: $filmSlug\n      paymentIntentId: $paymentIntentId\n    )\n      @rest(\n        type: \"PaymentIntent\"\n        endpoint: \"v2\"\n        path: \"tvod/stripe-intent?tierSlug={args.tierSlug}&tierSku={args.tierSku}&filmSlug={args.filmSlug}&paymentIntentId={args.paymentIntentId}\"\n      ) {\n      clientSecret\n      paymentIntentId\n    }\n  }\n",
      "name": "GraphQL request",
      "locationOffset": {
        "line": 1,
        "column": 1
      }
    }
  }
};
/** Payment intents are used for once-off payments; they are still set up to allow future off-session payments */

export var useTVODCardPaymentIntentQuery = function useTVODCardPaymentIntentQuery(_ref, options) {
  var tvodTier = _ref.tvodTier,
      filmSlug = _ref.filmSlug,
      paymentIntentId = _ref.paymentIntentId;

  var _useFlixApiContext = useFlixApiContext(),
      events = _useFlixApiContext.events;

  useEffect(function () {
    tvodTier && events.emit(CHECKOUT_INITIATED, tvodTier.sku, tvodTier.currency, tvodTier.price / 100);
  }, []); // eslint-disable-line

  return useQuery(CARD_PAYMENT_INTENT, _objectSpread(_objectSpread({}, options), {}, {
    fetchPolicy: 'network-only',
    variables: {
      tierSlug: tvodTier.slug,
      tierSku: tvodTier.sku,
      filmSlug: filmSlug,
      paymentIntentId: paymentIntentId !== null && paymentIntentId !== void 0 ? paymentIntentId : ''
    }
  }));
};