import { createContext, useContext, useMemo, useState } from 'react'
import noop from 'lodash/noop'

const DURATION = 5000
const empty = {}

const SnackbarContext = createContext({
  snackbar: {
    message: '',
    open: false,
    values: null,
    duration: DURATION,
    isError: false,
  },
  closeSnackbar: noop,
  snackbarMsg: (message, values, duration) => {},
  snackbarError: (message, values, duration) => {},
})

export const SnackbarProvider = ({ SnackbarComponent, children }) => {
  const [snackbar, setSnackbar] = useState(empty)

  const contextValue = useMemo(
    () => ({
      closeSnackbar: () => setSnackbar(empty),

      snackbarMsg: (message, values, duration = DURATION) => {
        setSnackbar({ open: true, message, values, duration })
      },

      snackbarError: (message, values, duration = DURATION) => {
        setSnackbar({
          open: true,
          // Works for error.message, allowing for simply onError={snackbarError}
          message: typeof message === 'string' ? message : message?.message || 'Unknown error',
          values,
          duration,
          isError: true,
        })
      },
    }),
    [],
  )

  return (
    <SnackbarContext.Provider value={contextValue}>
      {children}
      <SnackbarComponent
        {...snackbar}
        title={snackbar?.message}
        autoHideDuration={snackbar?.duration}
        onRequestClose={() => setSnackbar(empty)}
      />
    </SnackbarContext.Provider>
  )
}

export const useSnackbar = () => useContext(SnackbarContext)
