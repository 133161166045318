import { defineMessages } from 'react-intl'

export default defineMessages({
  // Download status
  download: {
    id: 'app.downloads.download',
    defaultMessage: 'Download',
  },
  downloading: {
    id: 'app.downloads.downloading',
    defaultMessage: 'Downloading',
  },
  downloaded: {
    id: 'app.downloads.downloaded',
    defaultMessage: 'Downloaded',
  },
  paused: {
    id: 'app.downloads.paused',
    defaultMessage: 'Paused',
  },
  queued: {
    id: 'app.downloads.queued',
    defaultMessage: 'Queued',
  },
  expired: {
    id: 'app.downloads.expired',
    defaultMessage: 'Expired',
  },
  error: {
    id: 'app.downloads.error',
    defaultMessage: 'Error',
  },

  // Action sheet CTAs
  cancel: {
    id: 'app.downloads.cancel',
    defaultMessage: 'Cancel',
  },
  cancelDownload: {
    id: 'app.downloads.cancelDownload',
    defaultMessage: 'Cancel download',
  },
  removeDownload: {
    id: 'app.downloads.removeDownload',
    defaultMessage: 'Delete download',
  },
  pauseAllDownloads: {
    id: 'app.downloads.pauseAllDownloads',
    defaultMessage: 'Pause all',
  },
  resumeAllDownloads: {
    id: 'app.downloads.resumeAllDownloads',
    defaultMessage: 'Resume all',
  },
  playFilm: {
    id: 'app.downloads.playFilm',
    defaultMessage: 'Play film',
  },
  viewDetails: {
    id: 'app.downloads.viewDetails',
    defaultMessage: 'View details',
  },
  viewAllDownloads: {
    id: 'app.downloads.viewAllDownloads',
    defaultMessage: 'View all downloads',
  },

  // Empty Downloads List
  downloadsEmpty: {
    id: 'app.downloads.empty',
    defaultMessage: 'Your Downloads are empty',
  },
  downloadsEmptyOnlineInstruction: {
    id: 'app.downloads.emptyOnlineInstruction',
    defaultMessage: 'Head over to Now Showing to find and download films from our catalogue.',
  },
  downloadsEmptyOfflineInstruction: {
    id: 'app.downloads.emptyOfflineInstruction',
    defaultMessage:
      "When you're next online, head over to Now Showing to find and download films from our catalogue.",
  },
  downloadsEmptyButton: {
    id: 'app.downloads.emptyButton',
    defaultMessage: 'Browse Now Showing',
  },
  downloadsEmptyPick: {
    id: 'app.downloads.emptyPick',
    defaultMessage: 'Pick from our wide range of independent films Now Showing.',
  },
  downloadsEmptyClick: {
    id: 'app.downloads.emptyClick',
    defaultMessage: 'Click the Download button.',
  },
  downloadsEmptyWatch: {
    id: 'app.downloads.emptyWatch',
    defaultMessage: 'Watch anytime, anywhere.',
  },

  // Mobile connection alert
  noWifiAlertTitle: {
    id: 'app.downloads.noWifiAlertTitle',
    defaultMessage: 'Download without WiFi?',
  },
  noWifiAlertBody: {
    id: 'app.downloads.noWifiAlertBody',
    defaultMessage: 'You are not on WiFi. Are you sure you want to download using mobile data?',
  },
  noWifiAlertConfirm: {
    id: 'app.downloads.noWifiAlertConfirm',
    defaultMessage: 'Yes',
  },
  noWifiAlertReject: {
    id: 'app.downloads.noWifiAlertReject',
    defaultMessage: 'No',
  },

  // Errors
  errorTitle: {
    id: 'app.downloads.errorTitle',
    defaultMessage: 'Download Error',
  },
  close: {
    id: 'app.downloads.close',
    defaultMessage: 'Close',
  },
})
