import { defineMessages } from 'react-intl'

export default defineMessages({
  // Placeholders
  email: {
    id: 'app.forms.email',
    defaultMessage: 'Email address',
  },
  username: {
    id: 'app.forms.username',
    defaultMessage: 'Username',
  },
  name: {
    id: 'app.forms.name',
    defaultMessage: 'First & last name',
  },
  fullName: {
    id: 'app.forms.fullName',
    defaultMessage: 'Full name',
  },
  firstName: {
    id: 'app.forms.firstName',
    defaultMessage: 'First name',
  },
  surname: {
    id: 'app.forms.surname',
    defaultMessage: 'Surname',
  },
  password: {
    id: 'app.forms.password',
    defaultMessage: 'Password',
  },
  choosePassword: {
    id: 'app.forms.choosePassword',
    defaultMessage: 'Choose a Password',
  },
  cardHolderName: {
    id: 'app.forms.cardHolderName',
    defaultMessage: 'Card holder name',
  },

  changePassword: {
    id: 'app.forms.changePassword',
    defaultMessage: 'Change Password',
  },
  currentPwd: {
    id: 'app.forms.currentPwd',
    defaultMessage: 'Current Password',
  },
  newPwd: {
    id: 'app.forms.newPwd',
    defaultMessage: 'New Password',
  },
  confirmPwd: {
    id: 'app.forms.confirmPwd',
    defaultMessage: 'Confirm Password',
  },
  changePasswordSuccess: {
    id: 'app.forms.changePasswordSuccess',
    defaultMessage: 'Your password has been updated',
  },
  pwdWithEmailUpdate: {
    id: 'app.forms.ProfileInfoForm.pwdWithEmailUpdate',
    defaultMessage: 'Please provide your password when updating your email address.',
  },
  saveProfile: {
    id: 'app.forms.ProfileInfoForm.saveProfile',
    defaultMessage: 'Save Profile',
  },

  // Submit messages
  next: {
    id: 'app.forms.next',
    defaultMessage: 'Next',
  },
  submit: {
    id: 'app.forms.submit',
    defaultMessage: 'Submit',
  },
  resubmit: {
    id: 'app.forms.resubmit',
    defaultMessage: 'Resubmit',
  },
  update: {
    id: 'app.form.update',
    defaultMessage: 'Update',
  },
  createAccount: {
    id: 'app.forms.createAccount',
    defaultMessage: 'Create an account',
  },
  signIn: {
    id: 'app.forms.signIn',
    defaultMessage: 'Sign In',
  },
  signUp: {
    id: 'app.forms.signUp',
    defaultMessage: 'Sign Up',
  },
  resetPassword: {
    id: 'app.forms.resetPassword',
    defaultMessage: 'Reset password',
  },
  passwordResetSent: {
    id: 'app.forms.passwordResetSent',
    defaultMessage: 'Password reset link sent, check your inbox in a few moments.',
  },
  resendVerification: {
    id: 'app.forms.resendVerification',
    defaultMessage: 'Resend email',
  },
  saveCard: {
    id: 'app.forms.saveCard',
    defaultMessage: 'Save card',
  },
  cardSaved: {
    id: 'app.forms.cardSaved',
    defaultMessage: 'Your new card has been saved',
  },
  subscribe: {
    id: 'app.forms.subscribe',
    defaultMessage: 'Subscribe',
  },
  changeLanguage: {
    id: 'app.forms.changeLanguage',
    defaultMessage: 'Change language',
  },
  continue: {
    id: 'app.forms.continue',
    defaultMessage: 'Continue',
  },
  confirm: {
    id: 'app.forms.confirm',
    defaultMessage: 'Confirm',
  },
  complete: {
    id: 'app.forms.complete',
    defaultMessage: 'Complete',
  },
  back: {
    id: 'app.forms.back',
    defaultMessage: 'Back',
  },

  // In progress
  submitting: {
    id: 'app.forms.submitting',
    defaultMessage: 'Submitting…',
  },
  saving: {
    id: 'app.forms.saving',
    defaultMessage: 'Saving…',
  },

  cancel: {
    id: 'app.forms.cancel',
    defaultMessage: 'Cancel',
  },
})
