import { IconButton } from '../../../../elements/controls/IconButton/IconButton.js'
import messages from '../../messages.js'

export const FeatureButton = ({ onPress, userMayPlay, withLabel = true, ...props }) => (
  <IconButton
    {...props}
    name="play"
    onPress={onPress}
    intlMsg={withLabel ? (userMayPlay ? messages.playMovie : messages.watchFree) : undefined}
  />
)
