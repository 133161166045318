import { useRef } from 'react'
import { Field, useForm } from 'react-final-form'
import { View } from 'react-native'
import formMsgs from '@flix/common/elements/forms/messages'
import { requiredFullName } from '@flix/common/elements/forms/validators'
import { TextInput } from '@flix/common/elements/input'
import { StripeCardInput } from '../../../containers/stripe/components/StripeCardInput'

export const TVODNewCardPayment = () => {
  const cardField = useRef(null)

  const form = useForm()

  return (
    <View>
      <Field
        name="name"
        validate={requiredFullName}
        render={(fieldProps) => (
          <TextInput
            {...fieldProps}
            placeholder={formMsgs.cardHolderName}
            textContentType="name"
            autoCapitalize="words"
            onSubmitEditing={() => cardField.current?.focus()}
            blurOnSubmit={false}
            returnKeyType="next"
          />
        )}
      />
      <Field
        name="card"
        render={(fieldProps) => (
          <StripeCardInput {...fieldProps} onReady={(el) => (cardField.current = el)} />
        )}
        onValidated={(errors) => form.mutators.setFieldData('card', { error: errors.card })}
      />
    </View>
  )
}
