import { useEffect } from 'react'
import { useField } from 'react-final-form'
import { FormattedMessage } from 'react-intl'
import { Pressable, View } from 'react-native'
import styled from 'styled-components'
import { RadioButton } from '@flix/common/elements/input'
import { Text } from '@flix/common/elements/typography'
import messages from '../../profile/ProfilePage/CardsSection/messages'

const SavedCardsView = styled(View)`
  justify-content: center;
  align-items: center;
  margin-bottom: ${(props) => props.theme.spacing.large}px;
`

const AddPaymentMethod = styled(Text)`
  font-size: ${(props) => props.theme.fontSizes.small}px;
  margin-top: ${(props) => props.theme.spacing.xsmall}px;
`

export const TVODSavedCardPayment = ({ fieldName, userCards, setNewCardMethod }) => {
  const {
    input: { value, onChange },
  } = useField(fieldName)

  useEffect(() => {
    const defaultCard = userCards.find((card) => card.isDefault)
    if (defaultCard) {
      onChange(defaultCard.id)
    }

    return () => onChange(undefined)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SavedCardsView>
      {userCards.map((card, index, array) => (
        <RadioButton
          key={index}
          checked={value === card.id}
          label={
            <span>
              <FormattedMessage {...messages.cardNo} />: xxxx-xxxx-xxxx-
              {card.last4}
            </span>
          }
          onPress={() => onChange(card.id)}
          mb={index === array.length - 1 ? 'none' : 'small'}
        />
      ))}

      <Pressable onPress={() => setNewCardMethod(true)}>
        <AddPaymentMethod>Add a payment method</AddPaymentMethod>
      </Pressable>
    </SavedCardsView>
  )
}
