import { defineMessages } from 'react-intl'

export default defineMessages({
  defaultEmpty: {
    id: 'app.contentLists.defaultEmpty',
    defaultMessage: 'There are no items in this list',
  },
  filmsEmpty: {
    id: 'app.contentLists.filmsEmpty',
    defaultMessage: 'There are no films in this category',
  },
})
