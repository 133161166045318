import PropTypes from 'prop-types'
import { IconButton } from '../../../../elements/controls/IconButton/IconButton.js'
import messages from '../../messages.js'

export const GroupWatchButton = ({ onPress, withLabel = true, ...props }) => (
  <IconButton
    {...props}
    name="users"
    intlMsg={withLabel && messages.groupWatch}
    onPress={onPress}
  />
)

GroupWatchButton.propTypes = {
  onPress: PropTypes.func,
}
