import { FormattedMessage } from 'react-intl'
import { TouchableOpacity, View } from 'react-native'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { H3, Text } from '../../../elements/typography'
import { formatVeryShortLocalized } from '../../../utils/dates'
import { FilmLandscapePoster } from '../../content/FilmLandscapePoster/FilmLandscapePoster.js'
import messages from '../messages'

const Row = styled(View)`
  flex-direction: row;
  background: ${(props) => props.theme.colors.grayDarker};
`

const Content = styled(View)`
  flex: 1;
  padding: ${(props) => props.theme.spacing.xsmall}px ${(props) => props.theme.spacing.small}px;
`

const Title = styled(H3)`
  font-size: ${(props) => props.theme.fontSizes.medium}px;
  line-height: ${(props) => props.theme.fontSizes.medium * 1.3}px;
`

const Host = styled(Text)`
  color: ${(props) => props.theme.colors.accent};
  font-size: ${(props) => props.theme.fontSizes.small}px;
  line-height: ${(props) => props.theme.fontSizes.small * 1.3}px;
`

const SchedulingInfo = styled(Text)`
  font-size: ${(props) => props.theme.fontSizes.xsmall}px;
  line-height: ${(props) => props.theme.fontSizes.xsmall * 1.3}px;
`

const LiveCircle = styled(View)`
  height: 8px;
  width: 8px;
  border-radius: 5px;
  margin-right: ${(props) => props.theme.spacing.xs}px;
  background: ${(props) => props.theme.colors.primaryText};
`

const LiveText = styled(Host)`
  color: ${(props) => props.theme.colors.primaryText};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-family: ${(props) => props.theme.fontFamilies.baseBold};
`

export const GroupWatchListItem = ({ groupWatchItem, width, onChosen }) => {
  const { film, scheduledAt, title, userStatus, groupStatus } = groupWatchItem

  const imageWidth = width * 0.4

  return (
    <TouchableOpacity onPress={() => onChosen(groupWatchItem)}>
      <Row style={{ width }}>
        <FilmLandscapePoster item={film} width={imageWidth} />
        <Content>
          <Title mb="none" numberOfLines={1}>
            {title || film.title}
          </Title>

          {groupStatus === 'created' && scheduledAt && (
            <SchedulingInfo>
              <FormattedMessage {...messages.scheduledShort} />:{' '}
              {formatVeryShortLocalized(scheduledAt)}
            </SchedulingInfo>
          )}

          {userStatus === 'host' && (
            <Host>
              <FormattedMessage {...messages.host} />
            </Host>
          )}

          {groupStatus === 'started' && (
            <Row style={{ alignItems: 'center', marginTop: 'auto' }}>
              <LiveCircle />
              <LiveText>
                <FormattedMessage {...messages.live} />
              </LiveText>
            </Row>
          )}
        </Content>
      </Row>
    </TouchableOpacity>
  )
}

GroupWatchListItem.propTypes = {
  groupWatchItem: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
  onChosen: PropTypes.func.isRequired,
}
