import { useIntl } from 'react-intl'
import { Text } from 'react-native'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { media } from '@flix/common/style/media'
import { reverseArrIfRTL } from '@flix/common/style/rtlStyles'
import { formatDuration } from '../formatDuration.js'
import messages from '../messages'

const SubTitleBody = styled(Text)`
  color: ${(props) => props.theme.colors.grayLighte};
  font-size: ${(props) => props.theme.fontSizes.xsmall}px;
  text-align: left;
  ${media.sm`
    font-size: ${(props) => props.theme.fontSizes.small}px;
  `}
`

const SubTitle = ({ ageRestriction, genres = [], durationSeconds, seasonsCount }) => {
  const intl = useIntl()
  return (
    <SubTitleBody>
      {reverseArrIfRTL(
        [
          ageRestriction,
          genres.join(', '),
          durationSeconds && formatDuration(intl, durationSeconds),
          seasonsCount && intl.formatMessage(messages.seasonsCount, { count: seasonsCount }),
        ].filter(Boolean),
      ).join(' | ')}
    </SubTitleBody>
  )
}

SubTitle.propTypes = {
  ageRestriction: PropTypes.string,
  genres: PropTypes.arrayOf(PropTypes.string),
  durationSeconds: PropTypes.number,
}

export default SubTitle
