import { Component } from 'react'
import { differenceInSeconds, isAfter, parseJSON } from 'date-fns'

export class CountdownTimer extends Component {
  get date() {
    return parseJSON(this.props.date)
  }

  componentDidMount() {
    if (isAfter(this.date, Date.now())) {
      this.intervalID = setInterval(this.everySecond, 1000)
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalID)
  }

  everySecond = () => {
    if (!isAfter(this.date, Date.now())) {
      clearInterval(this.intervalID)
      this.props.onZero && this.props.onZero()
    }
    this.forceUpdate()
  }

  render() {
    const seconds = differenceInSeconds(this.date, Date.now())
    return this.props.children(seconds > 0 ? seconds : 0)
  }
}
