export const secondsToDuration = (secs) => {
  const millis = secs % 1 ? Math.floor((secs % 1) * 1000) : 0

  const days = Math.floor(secs / (3600 * 24))
  secs -= days * 3600 * 24

  const hrs = Math.floor(secs / 3600)
  secs -= hrs * 3600

  const mins = Math.floor(secs / 60)
  secs = Math.floor(secs - mins * 60)

  return { days, hrs, mins, secs, millis }
}

export const calcProgressPercent = (seekTime, totalTime) =>
  seekTime > 0 && totalTime > 0 ? seekTime / totalTime : 0

export const padZero = (num) => num.toString().padStart(2, '0')

export const formatTime = (seconds, collapseHours = false, minimumFractionalDigits = 0) => {
  const { hrs, mins, secs, millis } = secondsToDuration(seconds)
  const hours = collapseHours ? (hrs ? `${hrs}:` : '') : `${padZero(hrs)}:`
  const mSecs = minimumFractionalDigits
    ? '.' +
      millis.toString().substr(0, minimumFractionalDigits).padEnd(minimumFractionalDigits, '0')
    : ''
  return `${hours}${padZero(mins)}:${padZero(secs)}${mSecs}`
}

export const parseTime = (timeString = '', onlyFullInput = false) => {
  const [mainTime, millis = '0'] = timeString.split('.')
  const [secs = 0, mins = 0, hrs = 0] = mainTime.split(':').reverse()

  if (
    onlyFullInput &&
    (millis.length !== 3 || secs.length !== 2 || mins.length !== 2 || hrs.length !== 2)
  ) {
    return undefined
  }

  return durationToSeconds({
    hrs: Number(hrs),
    mins: Number(mins),
    secs: Number(secs),
    millis: Number(millis.padEnd(3, '0')),
  })
}

export const durationToSeconds = ({ days = 0, hrs = 0, mins = 0, secs = 0, millis = 0 }) =>
  days * 24 * 60 * 60 + hrs * 60 * 60 + mins * 60 + secs + millis / 1000
