"use strict";

export var BANNERS_LIST = 'banners';
export var POSTERS_LIST = 'posters';
export var POSTERS_LOGO_LIST = 'posters-logo';
export var POSTERS_PORTRAIT_LIST = 'posters-portrait';
export var FEATURED_PORTRAIT_LIST = 'featured-portrait';
export var PROMO_PANELS_LIST = 'promo-panels';
export var DOWNLOADS_LIST = 'downloads';
export var GROUP_WATCH = 'groupWatch';
export var FESTIVAL_LINEUP = 'festivalLineup';
export var LIVE_EVENTS = 'liveEvents';
export var CATCH_UP_MORE_CHANNELS = 'catch-up-more-channels';
export var TOP_CHANNELS_LIST = 'promo-channel-panels';