import PropTypes from 'prop-types'
import { IconButton } from '../../../../../elements/controls/IconButton/IconButton.js'
import messages from '../../../../downloads/messages.js'

export const DownloadStartButton = ({ onPress }) => (
  <IconButton name="download" intlMsg={messages.download} onPress={onPress} />
)

DownloadStartButton.propTypes = {
  onPress: PropTypes.func.isRequired,
}
