import { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { StyleSheet, View } from 'react-native'
import styled, { useTheme } from 'styled-components'
import { useButtonHandlers } from '../../../elements/controls'
import { ImageList } from '../../../elements/images/ImageList/ImageList'
import { MaxWidthView, useDimensions } from '../../../elements/layout'
import { ComponentPlaceholder } from '../../../elements/status/index.js'
import { H1, H3 } from '../../../elements/typography'
import { breakPoints } from '../../../style/breakPoints.js'
import { media, useMediaBreakpoints } from '../../../style/media'
import { Description } from '../../common/Description/Description'
import { PosterContent } from '../../content-lists/PosterContent/PosterContent.js'
import { ContentList } from '../../content-lists/index.js'
import { FilmPoster } from '../FilmPoster/FilmPoster'
import StarRating from '../StarRating/StarRating'
import SubTitle from '../SubTitle/SubTitle'
import { userMayView } from '../viewStatus'
import { CreditsDesktop } from './Credits/CreditsDesktop'
import { CreditsMobile } from './Credits/CreditsMobile'
import { FilmDetailsLeaderboardAd } from './FilmDetailsLeaderboardAd/FilmDetailsLeaderboardAd'
import { FilmOverviewBannerWithMobilePlay } from './FilmOverviewBanner/FilmOverviewBanner'
import { WatchButtons } from './WatchButtons/WatchButtons'
import { useWatchButtons } from './WatchButtons/useWatchButtons'
import messages from './messages'

const MainContent = styled(View)`
  margin-top: ${(props) => props.marginTop}px;
  /* Using 'width' here causes the section to flow off the screen */
  max-width: 100%;
`

const Section = styled(View)`
  width: 100%;
  border-color: ${(props) => props.theme.colors.primary};
  border-bottom-width: ${(props) => (props.border ? `${StyleSheet.hairlineWidth}px` : 0)};
  padding: ${(props) => props.theme.spacing.md}px ${(props) => props.theme.spacing.sm}px;
  padding-bottom: ${(props) => props.theme.spacing[props.pb ?? 'medium']}px;
`

const Border = styled(View)`
  width: 100%;
  border-color: ${(props) => props.theme.colors[props.color || 'primary']};
  border-bottom-width: ${(props) => (props.border ? `${StyleSheet.hairlineWidth}px` : 0)};
`

const SectionHeading = styled(H3)`
  ${media.sm`
    font-size: ${(props) => props.theme.fontSizes.xlarge}px;
  `}
  ${media.md`
    font-size: ${(props) => props.theme.fontSizes.xxlarge}px;
  `}
`

// const SectionViewMore = styled(Text)`
//   font-size: ${(props) => props.theme.fontSizes.small}px;
//   ${media.sm`
//     font-size: ${(props) => props.theme.fontSizes.medium}px;
//   `}
// `

const RatingAndButtonsRow = styled(View)`
  margin-bottom: ${(props) => props.theme.spacing.md}px;

  ${media.sm`
    align-items: flex-end;
    flex-direction: row;
 `}
`

const RatingAndDetails = styled(View)`
  min-width: ${(props) => (props.minWidth ? `${props.minWidth}px` : 'none')};
  margin-bottom: ${(props) => props.theme.spacing.md}px;

  ${media.sm`
    margin-right: ${(props) => props.theme.spacing.medium}px;
    margin-bottom: 0;
  `}
`

const FilmDescription = styled(Description)`
  flex: 1 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: 20px;
  padding-left: ${(props) => props.theme.spacing.md}px;
  ${media.md`
    padding-right: ${(props) => props.theme.spacing.md}px;
  `}
`

const CreditsSectionDesktop = styled(View)`
  width: 250px;
  padding: ${(props) => props.theme.spacing.sm}px;
  padding-top: 0;
`

const CreditsHeadingDesktop = styled(H3)`
  font-size: ${(props) => props.theme.fontSizes.large}px;
  padding-bottom: ${(props) => props.theme.spacing.sm}px;
`

const MediaContainer = styled(View)`
  padding: 0 ${(props) => props.theme.spacing[props.sidePaddingGutter] / 2}px;
  margin-bottom: ${(props) => props.theme.spacing.larger}px;
  width: 100%;
`

const PosterAndDescription = styled(View)`
  flex-direction: row;
  margin-bottom: ${(props) => props.theme.spacing.md}px;
`

export const FilmDetails = (props) => (
  <FilmDetailsBase BannerComponent={FilmOverviewBannerWithMobilePlay} {...props} />
)

const empty = {}

export const FilmDetailsBase = ({
  film,
  isDownloadAvailable,
  offlineImages,
  onFullScreenStills,
  BannerComponent,
  bannerProps = empty,
  keepBannerClear,
  isOnReminders,
  isOnWatchList,
  // nftItems,
  recommendedList,
  bannerAdsEnabled,
}) => {
  const isOffline = !!offlineImages
  const { handlers, authStatus } = useButtonHandlers()
  const onBannerPlayPress = userMayView(film, authStatus.hasSubscription, isOffline)
    ? () => handlers.playFeature(film)
    : () => handlers.playTrailer(film)

  const theme = useTheme()
  const { rotationSafeWidth: width } = useDimensions()
  const mediaBreakpoints = useMediaBreakpoints()
  const { sm, md } = mediaBreakpoints
  const {
    title,
    rating,
    durationSeconds,
    credits = [],
    longDescription,
    ageRestriction,
    genres,
  } = film

  const sectionPadding = theme.spacing.sm
  const posterDescrGutter = theme.spacing.md
  const leftPercentage = 0.35
  const leftMaxWidth = 250
  const contentMaxWidth = breakPoints.lg

  const contentWidth = Math.min(contentMaxWidth, width) - sectionPadding * 2
  const leftSectionWidth = Math.min(leftMaxWidth, contentWidth * leftPercentage - posterDescrGutter)
  const rightSectionWidth = contentWidth - leftSectionWidth

  const { visibleButtonsCount } = useWatchButtons({ isDownloadAvailable, isOffline, film })
  const isTablet = sm && !md
  const tabletButtonsBelowRating = isTablet && visibleButtonsCount > 5

  const recommendations = useMemo(() => {
    const limit = Math.min(5, PosterContent.getNumColumns(mediaBreakpoints))
    return (
      !recommendedList.loading && {
        ...recommendedList,
        items: recommendedList.items.slice(0, limit) || [],
      }
    )
  }, [mediaBreakpoints, recommendedList])

  return (
    <>
      <BannerComponent
        width={width}
        mobileLayout={!sm}
        onPlayPress={onBannerPlayPress}
        source={offlineImages?.mobileFilmOverviewBanner}
        publicId={sm ? film.detailsBannerImg : film.detailsBannerMobileImg}
        {...bannerProps}
      />

      <MaxWidthView overrideMediaWidth maxWidth={contentMaxWidth}>
        <MainContent marginTop={keepBannerClear ? 0 : width * (sm ? -0.06 : -0.12)}>
          <Section>
            <H1>{title}</H1>

            <RatingAndButtonsRow
              style={
                tabletButtonsBelowRating && { flexDirection: 'column', alignItems: 'flex-start' }
              }
            >
              <RatingAndDetails
                style={tabletButtonsBelowRating && { marginBottom: theme.spacing.md }}
                minWidth={leftSectionWidth}
              >
                <StarRating rating={rating} />
                <SubTitle
                  genres={genres}
                  durationSeconds={durationSeconds}
                  ageRestriction={ageRestriction}
                />
              </RatingAndDetails>
              <WatchButtons
                isOnReminders={isOnReminders}
                isOnWatchList={isOnWatchList}
                film={film}
                isDownloadAvailable={isDownloadAvailable}
                isOffline={isOffline}
              />
            </RatingAndButtonsRow>

            <PosterAndDescription>
              <FilmPoster item={film} width={leftSectionWidth} source={offlineImages?.poster} />
              <FilmDescription content={longDescription ?? 'Description not available.'} />

              {!!credits.length && md && (
                <CreditsSectionDesktop>
                  <CreditsHeadingDesktop>
                    <FormattedMessage {...messages.credits} />
                  </CreditsHeadingDesktop>
                  <CreditsDesktop credits={credits} />
                </CreditsSectionDesktop>
              )}
            </PosterAndDescription>
          </Section>

          {!!credits.length && !md && (
            <Section border>
              <SectionHeading spaced>
                <FormattedMessage {...messages.credits} />
              </SectionHeading>
              <CreditsMobile
                credits={credits}
                keysWidth={leftSectionWidth}
                valuesWidth={rightSectionWidth}
              />
            </Section>
          )}
        </MainContent>

        {bannerAdsEnabled && (
          <FilmDetailsLeaderboardAd
            zone="filmDetails"
            placementSm="middleLeaderboard"
            placementXs="middleLeaderboardMobile"
            filmSlug={film.slug}
            genres={genres}
          />
        )}

        {/*{!!nftItems?.items.length && (*/}
        {/*  <>*/}
        {/*    <Section*/}
        {/*      pb="xs"*/}
        {/*      style={{*/}
        {/*        flexDirection: 'row',*/}
        {/*        justifyContent: 'space-between',*/}
        {/*        alignItems: 'center',*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      <SectionHeading>*/}
        {/*        <FormattedMessage {...messages.nfts} />*/}
        {/*      </SectionHeading>*/}
        {/*      <SectionViewMore>*/}
        {/*        <TextLink onPress={() => handlers.nftViewFilmCollection(film)} underline={false}>*/}
        {/*          <FormattedMessage {...messages.viewMore} />*/}
        {/*        </TextLink>*/}
        {/*      </SectionViewMore>*/}
        {/*    </Section>*/}
        {/*    <MediaContainer sidePaddingGutter="sm" flexDirection="row">*/}
        {/*      <NFTSimpleHorizontalList*/}
        {/*        maxWidth={contentWidth}*/}
        {/*        items={nftItems?.items}*/}
        {/*        onItemChosen={handlers.nftViewItem}*/}
        {/*      />*/}
        {/*    </MediaContainer>*/}
        {/*  </>*/}
        {/*)}*/}

        {/*<Border border={!!nftItems?.items.length} />*/}

        <Section pb="xs">
          <SectionHeading>
            <FormattedMessage {...messages.media} />
          </SectionHeading>
        </Section>
        <MediaContainer sidePaddingGutter="sm">
          <ImageList
            gutter="sm"
            width={contentWidth}
            aspectRatio={1.5}
            publicIds={film.stills ?? []}
            onItemChosen={onFullScreenStills}
          />
        </MediaContainer>

        {recommendedList.loading ? (
          <ComponentPlaceholder activityIndicator height={250} />
        ) : (
          !!recommendations.items.length && (
            <>
              <Border border color="grayLight" />
              <Section pb="xs">
                <SectionHeading>
                  <FormattedMessage {...messages.moreLikeThis} />
                </SectionHeading>
              </Section>
              <MediaContainer sidePaddingGutter="sm" flexDirection="row">
                <ContentList
                  list={recommendations}
                  onItemChosen={handlers.viewDetails}
                  bounces={false}
                  overScrollMode="never"
                  maxColumns={5}
                  ListFooterComponentStyle={{ display: 'none' }}
                />
              </MediaContainer>
            </>
          )
        )}
      </MaxWidthView>
    </>
  )
}
