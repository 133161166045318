import { memo } from 'react'
import { View } from 'react-native'
import styled from 'styled-components'
import { GroupWatchListItem } from '../../group-watch/GroupWatchListItem/GroupWatchListItem'

const PaddedWrapper = styled(View)`
  padding-left: ${(props) => props.theme.spacing.sm / 2}px;
  padding-right: ${(props) => props.theme.spacing.sm / 2}px;
  padding-bottom: ${(props) => props.theme.spacing.medium}px;
`

export const GroupWatchContent = memo(({ item, theme, width, onChosen }) => (
  <PaddedWrapper>
    <GroupWatchListItem
      groupWatchItem={item}
      onChosen={onChosen}
      width={width - theme.spacing.sm}
    />
  </PaddedWrapper>
))

GroupWatchContent.getNumColumns = ({ sm, md }) => (md ? 3 : sm ? 2 : 1)
