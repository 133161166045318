import { useIntl } from 'react-intl'
import { View } from 'react-native'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Paragraph } from '../../../elements/typography/index.js'
import { media } from '../../../style/media.js'
import { padZero, secondsToDuration } from '../../../utils/secondsToDuration.js'
import { CountdownTimer } from '../../common/CountdownTimer/CountdownTimer.js'
import messages from '../messages.js'

const Container = styled(View)`
  flex-direction: row;
  align-items: flex-start;

  ${media.sm`
    flex-direction: column;
  `}
`

const CountdownText = styled(Paragraph)`
  margin: 0;
  font-size: ${(props) => props.theme.fontSizes[props.xsFontSize]}px;
  color: ${(props) => props.theme.colors[props.color]};

  ${media.sm`
    font-size: ${(props) => props.theme.fontSizes[props.smFontSize]}px;
    line-height: ${(props) => props.theme.fontSizes[props.smFontSize]}px;
  `}

  ${media.md`
    font-size: ${(props) => props.theme.fontSizes[props.mdFontSize]}px;
    line-height: ${(props) => props.theme.fontSizes[props.mdFontSize]}px;
  `}
`

export const Countdown = ({
  onZero,
  targetDate,
  showZeroDays,
  hoursLabelIntl,
  minutesLabelIntl,
  secondsLabelIntl,
  xsFontSize = 'xsmall',
  smFontSize = 'xxlarge',
  mdFontSize = smFontSize,
  color = 'text',
}) => {
  const intl = useIntl()
  return (
    <Container>
      <CountdownTimer date={targetDate} onZero={onZero}>
        {(seconds) => {
          const { days, hrs, mins, secs } = secondsToDuration(seconds)

          const dayStr =
            days > 0 || showZeroDays ? intl.formatMessage(messages.days, { days }) + ' : ' : ''

          const hoursStr = !hoursLabelIntl ? hrs : intl.formatMessage(hoursLabelIntl, { hrs })

          const minsStr = !minutesLabelIntl
            ? padZero(mins)
            : intl.formatMessage(minutesLabelIntl, { mins })

          const secsStr = !secondsLabelIntl
            ? padZero(secs)
            : intl.formatMessage(secondsLabelIntl, { secs })

          return (
            <CountdownText
              color={color}
              xsFontSize={xsFontSize}
              smFontSize={smFontSize}
              mdFontSize={mdFontSize}
            >
              {dayStr}
              {hoursStr} : {minsStr} : {secsStr}
            </CountdownText>
          )
        }}
      </CountdownTimer>
    </Container>
  )
}

Countdown.propTypes = {
  targetDate: PropTypes.string,
  onZero: PropTypes.func,
}
