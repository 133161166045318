import { FormattedNumber } from 'react-intl'
import { IconButton } from '../../../../elements/controls/IconButton/IconButton.js'

export const PurchaseButton = ({ onPress, paymentModelConfig, filmPaymentModel, ...props }) => {
  const tier = paymentModelConfig?.tvod.items.find(
    (item) => item.slug === filmPaymentModel?.tvod[0],
  )

  return (
    <IconButton
      {...props}
      name="play"
      onPress={onPress}
      title={
        <>
          Purchase{' '}
          <FormattedNumber
            value={(tier?.price ?? 0) / 100}
            style="currency"
            currency={tier?.currency}
          />
        </>
      }
    />
  )
}
