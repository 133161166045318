import { View } from 'react-native'
import styled, { useTheme } from 'styled-components'
import { Text } from '../../../elements/typography/index.js'
import { defaultTransforms } from '../../../services/cloudinary/index.js'
import { media } from '../../../style/media.js'
import ActorPoster from '../ActorPoster/ActorPoster.js'

const Title = styled(Text)`
  position: absolute;
  width: ${(props) => props.width}px;
  padding: 0 ${(props) => props.theme.spacing.xsmall}px;
  bottom: ${(props) => props.theme.spacing.xsmall}px;
  font-size: ${(props) => props.theme.fontSizes.medium}px;
  text-align: center;

  ${media.sm`
    bottom: ${(props) => props.theme.spacing.small}px;
    font-size: ${(props) => props.theme.fontSizes.larger}px;
  `};

  ${media.md`
    bottom: ${(props) => props.theme.spacing.smedium}px;
  `};
`

export const ActorPosterNamed = ({ item, width, ...props }) => {
  const theme = useTheme()
  return (
    <View style={{ width }}>
      <ActorPoster
        {...props}
        item={item}
        width={width}
        transforms={[
          defaultTransforms,
          {
            background: theme.colors.background,
            effect: 'gradient_fade:10',
            y: -0.18,
          },
        ]}
      />
      <Title width={width} numberOfLines={1}>
        {item.name || item.title}
      </Title>
    </View>
  )
}
