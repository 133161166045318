import { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { Alert, TouchableOpacity } from 'react-native'
import messages from '../messages'

export const useDownloadErrorAlert = (errorText, onRemoveDownload) => {
  const intl = useIntl()
  return useCallback(() => {
    Alert.alert(intl.formatMessage(messages.errorTitle), errorText, [
      {
        text: intl.formatMessage(messages.close),
        onPress: onRemoveDownload,
      },
    ])
  }, [errorText, intl, onRemoveDownload])
}

export const DownloadErrorTouchable = ({ onRemoveDownload, errorText, ...props }) => {
  const openErrorAlert = useDownloadErrorAlert(errorText, onRemoveDownload)
  return <TouchableOpacity {...props} onPress={openErrorAlert} />
}
