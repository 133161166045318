import { useEffect } from 'react'
import { ImagePlaceholder } from '../../../elements/images/ImagePlaceholder/ImagePlaceholder'
import { useMediaIs } from '../../../style/media.js'
import { PromoPanelContainer } from '../../content/FilmPromoPanel/FilmPromoPanel.js'
import { AdPlacement } from '../AdPlacement.js'
import { useSkipListAd } from '../listAdInsertion/ListAdInsertionContext'
import { useBannerAdDecision } from '../useBannerAdDecision.js'

const dimensionsMobile = { width: 420, height: 283 }
const dimensions = { width: 1192, height: 441 }

export const PremierePanelAd = ({
  zone,
  placementXs,
  placementSm,
  index,
  width: explicitWidth,
}) => {
  const sm = useMediaIs('sm')

  const placement = (sm ? placementSm : placementXs) ?? placementSm ?? placementXs

  const { bannerData, loading } = useBannerAdDecision(zone, placement)

  const skipAd = useSkipListAd()

  useEffect(() => {
    if (!loading && bannerData?.message === 'no ads' && zone && placement) {
      skipAd(zone, placement, index)
    }
  }, [loading, bannerData?.message, zone, placement, index, skipAd])

  const sizes = sm ? dimensions : dimensionsMobile
  const width = explicitWidth ?? sizes.width
  const height = (width * sizes.height) / sizes.width

  return (
    <PromoPanelContainer>
      {loading ? (
        <ImagePlaceholder width={width} height={height} />
      ) : (
        <AdPlacement data={bannerData} width={width} height={height} />
      )}
    </PromoPanelContainer>
  )
}
