import { useEffect, useState } from 'react'
import { useElements, useStripe } from '@stripe/react-stripe-js'
import { FORM_ERROR } from 'final-form'
import {
  useLogStripeError,
  useSubmitTVODPaymentMutation,
  useTVODCardPaymentIntentQuery,
} from '@mwxltd/flix-api-client'
import bugsnag from '../../services/bugsnag'

const PAYMENT_PROVIDER = 'stripe-intent'
const PAYMENT_INTENT_ID = 'paymentIntentId'

const clientSecretStore = {
  set: (paymentIntent) => window.sessionStorage.setItem(PAYMENT_INTENT_ID, paymentIntent),
  get: () => window.sessionStorage.getItem(PAYMENT_INTENT_ID) ?? null,
  clear: () => window.sessionStorage.removeItem(PAYMENT_INTENT_ID),
}

export const useStripeTVODPaymentSubmission = ({ film, tvodTier, onSuccess, onError }) => {
  const stripe = useStripe()
  const elements = useElements()

  const [submitPayment] = useSubmitTVODPaymentMutation(
    { tvodTier, film },
    { onCompleted: onSuccess, onError },
  )

  // The previous payment intent is read on mount, but not again so a second payment intent
  // query isn't triggered when a new secret is returned by the API. On the next mount, the
  // latest value will again be read from the store though.
  const [prevIntent] = useState(clientSecretStore.get)

  const { data } = useTVODCardPaymentIntentQuery({
    tvodTier,
    filmSlug: film?.slug,
    paymentIntentId: prevIntent?.paymentMethodId,
  })
  const paymentIntentId = data?.paymentIntent?.paymentIntentId ?? null
  const clientSecret = data?.paymentIntent?.clientSecret ?? null

  useEffect(() => {
    paymentIntentId && clientSecretStore.set(paymentIntentId)
  }, [paymentIntentId])

  const { logStripeError } = useLogStripeError(bugsnag.notify)

  const handleCardSubmit = async ({ name, savedCardId }) => {
    try {
      const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: savedCardId
          ? savedCardId
          : {
              card: elements.getElement('card'),
              billing_details: { name },
            },
      })

      if (error) {
        logStripeError(error)
        return { [FORM_ERROR]: error.message }
      }

      const resultOrErrors = await submitPayment(PAYMENT_PROVIDER, {
        filmSlug: film?.slug,
        paymentIntentId: paymentIntent.id,
        paymentMethodId: paymentIntent.payment_method,
      })

      if (!resultOrErrors.data) {
        return resultOrErrors // Contains formatted final-form errors
      }

      // 3DSecure might require additional actions
      const status = resultOrErrors.data.confirmTVODPayment ?? {}
      if (!status.requiresAction) {
        clientSecretStore.clear() // Success, reset for the next payment
      } else {
        const { paymentIntent, error } = await stripe.confirmCardPayment(status.clientSecret)

        if (error) {
          logStripeError(error)
          return { [FORM_ERROR]: error.message }
        }

        const resultOrErrors = await submitPayment(PAYMENT_PROVIDER, {
          filmSlug: film?.slug,
          paymentIntentId: paymentIntent.id,
          confirmation: true,
        })

        if (!resultOrErrors.data) {
          return resultOrErrors // Contains formatted final-form errors
        }

        clientSecretStore.clear() // Success, reset for the next payment
      }
    } catch (error) {
      bugsnag.notify(error, (event) => {
        event.addMetadata('details', { name })
      })
      return { [FORM_ERROR]: error.message }
    }
  }

  return { handleCardSubmit }
}
