import { useMemo } from 'react'
import styled, { ThemeProvider, useTheme } from 'styled-components'
import PropTypes from 'prop-types'
import { Html } from '../../../elements/typography'

const DescriptionHtml = styled(Html)`
  margin-bottom: ${(props) => props.theme.spacing.md}px;
`

export const Description = (props) => {
  const theme = useTheme()
  const offWhiteFontTheme = useMemo(
    () => ({
      ...theme,
      fontSizes: {
        ...theme.fontSizes,
        medium: theme.fontSizes.small,
      },
      colors: {
        ...theme.colors,
        text: theme.colors.whiteFaded,
      },
    }),
    [theme],
  )
  return (
    <ThemeProvider theme={offWhiteFontTheme}>
      <DescriptionHtml {...props} />
    </ThemeProvider>
  )
}

Description.propTypes = {
  content: PropTypes.string.isRequired,
}

export default Description
