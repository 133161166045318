import { useState } from 'react'
import { TouchableOpacity, View } from 'react-native'
import styled, { useTheme } from 'styled-components'
import { Image } from '@flix/common/elements/images/Image/Image'
import { useMediaIs } from '@flix/common/style/media'
import { cursorPointer } from '@flix/common/style/webStyles'
import { ImageCarouselFullScreen } from '../ImageCarouselFullScreen/ImageCarouselFullScreen'

const FlexWrapper = styled(View)`
  flex-direction: row;
  flex-wrap: wrap;
  /* Just using width here causes the images to fail to display on mobile */
  min-width: 100%;
  max-width: 100%;
`

const Touchable = styled(TouchableOpacity)`
  width: ${(props) => props.width}px;
`

const ImageItem = styled(Image)`
  margin: 0 auto ${(props) => props.theme.spacing[props.gutter]}px;
  ${cursorPointer};
`

export const ImageList = ({ publicIds, aspectRatio, gutter, width, onItemChosen }) => {
  const [{ showCarousel, activeIndex }, setFullScreen] = useState({ showCarousel: false })

  const theme = useTheme()

  const handleItemChosen = (activeIndex) => {
    onItemChosen ? onItemChosen(activeIndex) : setFullScreen({ showCarousel: true, activeIndex })
  }

  const handleCloseCarousel = () => setFullScreen({ showCarousel: false })

  const sm = useMediaIs('sm')
  const columns = sm ? 3 : 2
  const itemWidth = Math.floor(width / columns)
  const imageWidth = itemWidth - theme.spacing[gutter]
  const imageHeight = imageWidth / aspectRatio

  return (
    <>
      <FlexWrapper>
        {!width
          ? null
          : publicIds.map((publicId, index) => (
              <Touchable key={publicId} width={itemWidth} onPress={() => handleItemChosen(index)}>
                <ImageItem
                  gutter={gutter}
                  width={imageWidth}
                  height={imageHeight}
                  alt={`Still ${index}`}
                  publicId={publicId}
                />
              </Touchable>
            ))}
      </FlexWrapper>

      <ImageCarouselFullScreen
        shown={showCarousel}
        initialSlide={activeIndex}
        items={publicIds}
        onRequestClose={handleCloseCarousel}
        aspectRatio={1.5}
      />
    </>
  )
}
