import { Helmet } from 'react-helmet-async'
import { useIntl } from 'react-intl'
import { useLocation, useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { parseJSON } from 'date-fns'
import format from 'date-fns/fp/format'
import {
  useFeatureFlag,
  useFilmDetailsQuery,
  useFilmRecommendedQuery,
  usePublishedNFTItemsQuery,
  useUserReminderSlugs,
  useUserWatchListSlugs,
} from '@mwxltd/flix-api-client'
import { FilmDetails } from '@flix/common/domain/content/FilmDetails/FilmDetails'
import { PaymentModelProvider } from '@flix/common/hooks/usePaymentModel/PaymentModelProvider'
// import { NFTFavoritesProvider } from '@flix/common/domain/nft/hooks/NFTFavoritesProvider.lazy'
import { Error404 } from '../../../containers/errors/Error404/Error404'
import { useWasBackNavigation } from '../../../containers/useWasBackNavigation/useWasBackNavigation'
import { canonicalUrl, imageOgTags, videoOgTags } from '../../../utils/htmlHead'
import meta from '../../metaDescriptions'
import { useGuardUnavailableContent } from '../GuardUnavailableContent/useGuardUnavailableContent'
import { TVODPurchaseModal } from './TVODPurchaseModal'

export const openGraphMediaFilm = (film) => {
  const video = film.trailer
  return {
    image: {
      cloudinaryId: film.posterImg,
      width: 600,
      height: 900,
      crop: 'pad',
    },
    video: video && { url: video.url, type: video.mime, width: 1920, height: 1080 },
    description: `${film.genres?.join('/') || film.title} | ${film.shortDescription}`,
  }
}

export const FilmDetailsPageBase = ({
  FilmDetailsComponent,
  film,
  loading,
  error,
  ...filmDetailsProps
}) => {
  const intl = useIntl()
  const location = useLocation()

  if (loading) return <div style={{ minHeight: '500px' }} />

  if (!film || error) return <Error404 item="film" />

  const ogMedia = openGraphMediaFilm(film)
  const releaseYear = film.releaseDate ? ` (${format('yyyy', parseJSON(film.releaseDate))})` : ''

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage(meta.contentDetails, { title: film.title, releaseYear })}</title>
        <meta name="description" content={ogMedia.description} />
        <meta property="og:type" content="video.movie" />
        <meta property="og:title" content={`${film.title} on Flix Premiere`} />
        <meta property="og:description" content={ogMedia.description} />
        <meta property="og:url" content={canonicalUrl(location)} />
        <link rel="canonical" href={canonicalUrl(location)} />
        {imageOgTags(ogMedia.image)}
        {videoOgTags(ogMedia.video)}
      </Helmet>

      <FilmDetailsComponent film={film} {...filmDetailsProps} />
    </>
  )
}

const FilmDetailsPage = () => {
  const { getFlagValue } = useFeatureFlag()
  const bannerAdsEnabled = getFlagValue('bannerAdsEnabled')

  const { filmSlug: slug } = useParams()
  const history = useHistory()

  const wasBackNav = useWasBackNavigation()

  const reminders = useUserReminderSlugs()
  const watchlist = useUserWatchListSlugs()

  const { data: { film } = {}, ...filmState } = useFilmDetailsQuery({
    slug,
    trackView: !wasBackNav,
  })

  // Skipping query as we have NFT Market Place disabled (!config.nftMarketplace.enabled)
  const { data: { nftPublishedItems } = {}, ...itemsState } = usePublishedNFTItemsQuery(
    { filmSlug: slug, limit: 4 },
    { skip: true },
  )

  const { data: { filmRecommendedList } = {}, loading } = useFilmRecommendedQuery(
    { filmId: film?.id, limit: 5 },
    { skip: !film },
  )

  // const nftFavorites = useNFTFavoritesHooks()
  useGuardUnavailableContent(film)

  return (
    <PaymentModelProvider>
      {/*<NFTFavoritesProvider value={nftFavorites}>*/}
      <FilmDetailsPageBase
        FilmDetailsComponent={FilmDetails}
        film={film}
        nftItems={nftPublishedItems}
        recommendedList={{ ...filmRecommendedList, loading }}
        loading={filmState.loading || itemsState.loading}
        error={filmState.error} // Ignore NFT load errors, just don't show
        isOnReminders={!!reminders[slug]}
        isOnWatchList={!!watchlist[slug]}
        bannerAdsEnabled={bannerAdsEnabled}
      />
      {/*</NFTFavoritesProvider>*/}

      <TVODPurchaseModal
        film={film}
        visible={!!history.location.state?.tvodPurchaseModalOpen}
        onRequestClose={() => history.replace(`/film/${slug}`)}
      />
    </PaymentModelProvider>
  )
}

export default FilmDetailsPage
