"use strict";

export var formatNFTFilterValues = function formatNFTFilterValues() {
  var _price$min, _price$max, _films$sort$map$join, _films, _categories$sort$map$, _tags$sort$map$join;

  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref$orderBy = _ref.orderBy,
      orderBy = _ref$orderBy === void 0 ? '' : _ref$orderBy,
      _ref$page = _ref.page,
      page = _ref$page === void 0 ? 0 : _ref$page,
      _ref$limit = _ref.limit,
      limit = _ref$limit === void 0 ? 50 : _ref$limit,
      _ref$query = _ref.query,
      query = _ref$query === void 0 ? '' : _ref$query,
      price = _ref.price,
      filmSlug = _ref.filmSlug,
      films = _ref.films,
      categories = _ref.categories,
      tags = _ref.tags;

  if (filmSlug) {
    films = films ? films.concat(filmSlug) : [filmSlug];
  }

  return {
    orderBy: orderBy,
    page: page + 1,
    limit: limit,
    query: query,
    priceMin: (_price$min = price === null || price === void 0 ? void 0 : price.min) !== null && _price$min !== void 0 ? _price$min : '',
    priceMax: (_price$max = price === null || price === void 0 ? void 0 : price.max) !== null && _price$max !== void 0 ? _price$max : '',
    films: (_films$sort$map$join = (_films = films) === null || _films === void 0 ? void 0 : _films.sort().map(function (slug, index) {
      return "film[".concat(index, "]=").concat(slug);
    }).join('&')) !== null && _films$sort$map$join !== void 0 ? _films$sort$map$join : '',
    categories: (_categories$sort$map$ = categories === null || categories === void 0 ? void 0 : categories.sort().map(function (slug, index) {
      return "category[".concat(index, "]=").concat(slug);
    }).join('&')) !== null && _categories$sort$map$ !== void 0 ? _categories$sort$map$ : '',
    tags: (_tags$sort$map$join = tags === null || tags === void 0 ? void 0 : tags.sort().map(function (id, index) {
      return "tag_id[".concat(index, "]=").concat(id);
    }).join('&')) !== null && _tags$sort$map$join !== void 0 ? _tags$sort$map$join : ''
  };
};