import styled from 'styled-components'
import messages from '@flix/common/domain/content/messages'
import { Button } from '@flix/common/elements/controls'

const ButtonWithMargin = styled(Button)`
  margin-right: ${(props) => props.theme.spacing.sm}px;
`

export const LineupPanelButtonsDesktop = ({
  addToWatchList,
  isOnWatchList,
  isPlayable,
  playFeature,
  playTrailer,
  removeFromWatchList,
  isFullySubscribed,
}) => {
  return (
    <>
      {isPlayable ? (
        <ButtonWithMargin
          icon="play"
          variant="primary"
          intlMsg={messages.playMovie}
          onPress={playFeature}
        />
      ) : (
        <ButtonWithMargin
          icon="play-clapboard"
          variant="primary"
          intlMsg={messages.playTrailer}
          onPress={playTrailer}
        />
      )}

      {isFullySubscribed && (
        <Button
          icon={isOnWatchList ? 'ticket-checked' : 'ticket-add'}
          intlMsg={messages.watchList}
          onPress={isOnWatchList ? removeFromWatchList : addToWatchList}
        />
      )}
    </>
  )
}
