import { FormattedMessage } from 'react-intl'
import { View } from 'react-native'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Paragraph } from '@flix/common/elements/typography'
import { media, useMediaIs } from '@flix/common/style/media'
import { formatLongLocalized, formatShortLocalized } from '@flix/common/utils/dates'
import { bold } from '../../../../style/commonStyles'
import { Countdown } from '../../components/Countdown'
import messages from '../../messages'

const Row = styled(View)`
  ${media.xs`
    flex-direction: row;
  `}
`

const StatusMessage = styled(Paragraph)`
  font-size: ${(props) => props.theme.fontSizes.xsmall}px;
  line-height: ${(props) => props.theme.fontSizes.xsmall}px;
  margin-bottom: ${(props) => props.theme.spacing.xs}px;
  ${media.sm`
    margin-bottom: ${(props) => props.theme.spacing.sm}px;
    font-size: ${(props) => props.theme.fontSizes.large}px;
    line-height: ${(props) => props.theme.fontSizes.large}px;
  `}
`

const CountdownAndText = styled(View)``

const FullDate = styled(StatusMessage)`
  ${bold};

  ${media.xs`
    margin-left: ${(props) => props.theme.spacing.xxs * 1.5}px;
  `}

  ${media.sm`
    margin-left: ${(props) => props.theme.spacing.xs}px;
    margin-bottom: ${(props) => props.theme.spacing.sm}px;
  `}
`

const StatusIntl = ({ intlMsg, style }) => (
  <StatusMessage style={style}>
    <FormattedMessage {...intlMsg} />
  </StatusMessage>
)

const CountdownStatusMsg = styled(StatusIntl)`
  line-height: ${(props) => props.theme.fontSizes.xs}px;
  margin: 0;

  ${media.xs`
    line-height: ${(props) => props.theme.fontSizes.medium}px;
  `}

  ${media.sm`
    margin-bottom:${(props) => props.theme.spacing.sm}px;
  `}
`

export const ScreeningStatus = ({
  film,
  isFinished,
  isFuture,
  isLaterToday,
  isLive,
  onZero,
  style,
}) => {
  const atLeastTablet = useMediaIs('sm')
  const formatDate = atLeastTablet ? formatLongLocalized : formatShortLocalized

  return (
    <View style={style}>
      {isFinished ? (
        <StatusIntl intlMsg={messages.finished} />
      ) : isLaterToday ? (
        <CountdownAndText>
          <CountdownStatusMsg intlMsg={messages.availableIn} />
          <Countdown
            onZero={onZero}
            hoursLabelIntl={messages.hoursShort}
            minutesLabelIntl={messages.minutesShort}
            secondsLabelIntl={messages.secondsShort}
            targetDate={film.festivalStartDate}
            smFontSize="large"
            mdFontSize="xlarge"
          />
        </CountdownAndText>
      ) : isFuture ? (
        <Row>
          <StatusIntl intlMsg={messages.availableFrom} />
          <FullDate>{formatDate(film.festivalStartDate)}</FullDate>
        </Row>
      ) : isLive ? (
        <Row>
          <StatusIntl intlMsg={messages.availableUntil} />
          <FullDate>{formatDate(film.festivalEndDate)}</FullDate>
        </Row>
      ) : null}
    </View>
  )
}

ScreeningStatus.propTypes = {
  film: PropTypes.shape({
    festivalStartDate: PropTypes.string.isRequired,
    festivalEndDate: PropTypes.string.isRequired,
  }).isRequired,
  isFinished: PropTypes.bool.isRequired,
  isFuture: PropTypes.bool.isRequired,
  isLaterToday: PropTypes.bool.isRequired,
  isLive: PropTypes.bool.isRequired,
  onZero: PropTypes.func.isRequired,
  style: PropTypes.object,
}
