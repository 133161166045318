import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from '../../../containers/app/Snackbar/SnackbarContext'

export const useGuardUnavailableContent = (content) => {
  const history = useHistory()
  const { snackbarMsg } = useSnackbar()

  useEffect(() => {
    if (content?.unavailableInRegion) {
      snackbarMsg(
        `${content.title} is not available in your region at the moment`,
        'But check out some of the other great titles now showing',
        { bsStyle: 'danger', duration: 6000 },
      )
      history.replace('/')
    }
  }, [content?.unavailableInRegion, content?.title, history, snackbarMsg])
}
