"use strict";

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import { useCallback, useState } from 'react';
import { ApolloError, useApolloClient } from '@apollo/client';
/**
 * React hook with the same API as the Apollo client `useMutation` hook but
 * with custom validation error handling.
 *
 * Should the network error contain an `errors` array the promise will resolve to
 * this error instead of being rejected. This smooths integration with libraries
 * like `react-final-form` which expect promises to resolve to validation errors.
 */

export var useMutationWithValidation = function useMutationWithValidation(mutation, options) {
  var client = useApolloClient();

  var _useState = useState({
    called: false,
    loading: false
  }),
      _useState2 = _slicedToArray(_useState, 2),
      result = _useState2[0],
      setResult = _useState2[1]; // https://github.com/apollographql/react-apollo/blob/master/packages/hooks/src/data/MutationData.ts#L124


  var mutate = useCallback(function (moreOptions) {
    var finalOptions = _objectSpread(_objectSpread(_objectSpread({}, options), moreOptions), {}, {
      mutation: mutation
    });

    var onCompleted = finalOptions.onCompleted,
        onError = finalOptions.onError,
        ignoreResults = finalOptions.ignoreResults;

    if (!ignoreResults) {
      setResult(function (state) {
        return state.loading ? state : {
          called: true,
          loading: true,
          error: undefined,
          data: undefined
        };
      });
    }

    return client.mutate(finalOptions).then(function (response) {
      var data = response.data,
          errors = response.errors;
      var error = errors && errors.length > 0 ? new ApolloError({
        graphQLErrors: errors
      }) : undefined;
      setResult({
        called: true,
        loading: false,
        data: data,
        error: error
      });
      onCompleted && onCompleted(data);
      return response;
    }).catch(function (error) {
      setResult({
        called: true,
        loading: false,
        data: undefined,
        error: error
      });
      var networkError = error.networkError;
      onError && onError(networkError);

      if (networkError && networkError.errors) {
        return networkError.errors;
      }

      if (onError) {
        onError(error);
      } else {
        throw error;
      }
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation, client].concat(_toConsumableArray(Object.values(options !== null && options !== void 0 ? options : {}))));
  return [mutate, result];
};