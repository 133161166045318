import { TouchableWithoutFeedback } from 'react-native'
import styled, { css } from 'styled-components'
import { Icon } from '../../../elements/images/Icon'
import { cursorPointer } from '../../../style/webStyles'
import { LinearGradient } from '../../images/LinearGradient'

const arrowStyle = css`
  position: absolute;
  height: 100%;
  width: ${(props) => props.theme.spacing.xlarge}px;
  justify-content: center;
  align-items: center;
  ${cursorPointer};
`

const leftFadeColors = (props) => [
  `rgba(0,0,0,${props.gradientOpacity})`,
  `rgba(0,0,0,${0.6 * props.gradientOpacity})`,
  'rgba(0,0,0,0)',
]
const rightFadeColors = (props) => [
  'rgba(0,0,0,0)',
  `rgba(0,0,0,${0.6 * props.gradientOpacity})`,
  `rgba(0,0,0,${props.gradientOpacity})`,
]

const LeftFade = styled(LinearGradient).attrs((props) => ({
  colors: !props.theme.isRTL ? leftFadeColors(props) : rightFadeColors(props),
  locations: [0, 0.7, 1],
  start: { x: 0, y: 0 },
  end: { x: 1, y: 0 },
}))`
  ${arrowStyle};
  left: 0;
`

const RightFade = styled(LinearGradient).attrs((props) => ({
  colors: !props.theme.isRTL ? rightFadeColors(props) : leftFadeColors(props),
  locations: [0, 0.3, 1],
  start: { x: 0, y: 0 },
  end: { x: 1, y: 0 },
}))`
  ${arrowStyle};
  right: 0;
`

const ChevronArrow =
  (ArrowArea, icon) =>
  ({ gradientOpacity = 1, ...props }) =>
    (
      <TouchableWithoutFeedback {...props}>
        <ArrowArea gradientOpacity={gradientOpacity}>
          <Icon name={icon} color="grayLighter" scale={1.3} />
        </ArrowArea>
      </TouchableWithoutFeedback>
    )

export const ChevronArrowLeft = ChevronArrow(LeftFade, 'chevron-left')
export const ChevronArrowRight = ChevronArrow(RightFade, 'chevron-right')
