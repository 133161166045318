import { memo } from 'react'
import { View } from 'react-native'
import styled, { useTheme } from 'styled-components'
import PropTypes from 'prop-types'
import { useButtonHandlers } from '../../../../elements/controls/index.js'
import { usePaymentModel } from '../../../../hooks/usePaymentModel/usePaymentModel.js'
import { useMediaBreakpoints } from '../../../../style/media.js'
import { useIsFilmDownloaded } from '../../../downloads/useIsFilmDownloaded.js'
import { PurchaseButton } from '../../WatchButtons/PurchaseButton/PurchaseButton.js'
import {
  DownloadButton,
  FeatureButton,
  GroupWatchButton,
  RecommendButton,
  ReminderButton,
  TrailerButton,
  WatchListButton,
} from '../../WatchButtons/index.js'
import { userMayView } from '../../viewStatus.js'
import { useWatchButtons } from './useWatchButtons.js'

const ButtonRow = styled(View)`
  flex-direction: row;
  justify-content: space-evenly;
`

export const WatchButtons = memo(
  ({ film, isDownloadAvailable, isOffline, isOnReminders, isOnWatchList }) => {
    const theme = useTheme()

    const { handlers, authStatus } = useButtonHandlers()

    const { paymentModelConfig, rentedFilms } = usePaymentModel()

    const userMayPlay = userMayView(
      film,
      authStatus.hasSubscription,
      isOffline,
      rentedFilms?.[film.slug],
    )
    const downloaded = useIsFilmDownloaded(film)

    const onPressReminder = !authStatus.hasSubscription
      ? handlers.signUp
      : isOnReminders
      ? handlers.cancelReminder
      : handlers.setReminder

    const onPressPlay = userMayPlay
      ? () => (downloaded ? handlers.playDownload : handlers.playFeature)(film)
      : handlers.signUp

    const { visibleButtonsCount, buttonsVisible } = useWatchButtons({
      isDownloadAvailable,
      isOffline,
      film,
      isRented: rentedFilms?.[film.slug],
    })

    const { sm, md: largeScreen } = useMediaBreakpoints()
    const isPhone = !sm
    const buttonMarginRight = !largeScreen ? 0 : theme.spacing.sm
    const wrap = isPhone && visibleButtonsCount > 4
    const firstRowButtonCount = wrap ? 3 : visibleButtonsCount
    const secondRowHasTwoButtons = visibleButtonsCount - firstRowButtonCount === 2

    // 10% seems to look best on tablet and desktop here
    const iconButtonWidth = wrap ? '25%' : '10%'

    const buttonsArr = [
      buttonsVisible.play && (
        <FeatureButton
          key="featureButton"
          width={iconButtonWidth}
          textDefinesWidth={!isPhone}
          onPress={onPressPlay}
          userMayPlay={userMayPlay}
          marginRight={isPhone ? 0 : theme.spacing.sm}
        />
      ),
      buttonsVisible.purchase && (
        <PurchaseButton
          key="purchaseButton"
          width={iconButtonWidth}
          textDefinesWidth={!isPhone}
          onPress={() => handlers.purchaseTVOD(film)}
          marginRight={isPhone ? 0 : theme.spacing.sm}
          paymentModelConfig={paymentModelConfig}
          filmPaymentModel={film.paymentModel}
        />
      ),
      buttonsVisible.playTrailer && (
        <TrailerButton
          key="trailerButton"
          width={iconButtonWidth}
          onPress={() => handlers.playTrailer(film)}
        />
      ),
      buttonsVisible.reminder && (
        <ReminderButton
          key="reminderButton"
          width={iconButtonWidth}
          isReminderSet={isOnReminders}
          onPress={onPressReminder}
          film={film}
          marginRight={buttonMarginRight}
        />
      ),
      buttonsVisible.download && <DownloadButton key="downloadButton" film={film} />,
      buttonsVisible.recommend && (
        <RecommendButton
          key="recommendButton"
          width={iconButtonWidth}
          onPress={() => handlers.recommendMovie(film, 'filmDetails')}
          marginRight={buttonMarginRight}
        />
      ),
      buttonsVisible.groupWatch && (
        <GroupWatchButton
          key="groupWatchButton"
          width={iconButtonWidth}
          onPress={() =>
            handlers.groupWatchHostScreen({ filmSlug: film.slug, festivalId: film.festivalId })
          }
        />
      ),
      buttonsVisible.watchList && (
        <WatchListButton
          key="watchListButton"
          onPress={
            isOnWatchList
              ? () => handlers.removeFromWatchList(film)
              : () => handlers.addToWatchList(film)
          }
          isOnWatchList={isOnWatchList}
          width={iconButtonWidth}
          marginRight={buttonMarginRight}
        />
      ),
    ].filter(Boolean)

    return (
      <View>
        <ButtonRow
          style={{
            marginBottom: wrap ? theme.spacing.md : undefined,
            justifyContent: wrap ? 'space-evenly' : 'flex-start',
          }}
        >
          {buttonsArr.slice(0, firstRowButtonCount)}
        </ButtonRow>
        <ButtonRow>
          {[
            secondRowHasTwoButtons && <Dummy key="dummy1" />,
            ...buttonsArr.slice(firstRowButtonCount),
            secondRowHasTwoButtons && <Dummy key="dummy2" />,
          ].filter(Boolean)}
        </ButtonRow>
      </View>
    )
  },
)

const Dummy = () => <View style={{ flex: 1 }} />

WatchButtons.propTypes = {
  film: PropTypes.object.isRequired,
  isDownloadAvailable: PropTypes.bool,
  isOffline: PropTypes.bool,
}
