import PropTypes from 'prop-types'
import { IconButton } from '../../../../../elements/controls/IconButton/IconButton.js'
import messages from '../../../../downloads/messages.js'

export const DownloadExpiredButton = ({ onPress }) => (
  <IconButton name="hourglass-finished" intlMsg={messages.expired} onPress={onPress} />
)

DownloadExpiredButton.propTypes = {
  onPress: PropTypes.func,
}
