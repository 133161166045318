import PropTypes from 'prop-types'
import { ImagePlaceholder } from '../../../elements/images/ImagePlaceholder/ImagePlaceholder'
import Poster from '../../../elements/images/Poster/Poster'
import { PosterPremiumOfferTitle } from '../../content-lists/PosterLandscapeContent/PosterPremiumOfferTitle'

export const FilmPoster = ({ item, width, premiumTitle, ...props }) => {
  if (!width || width <= 0) {
    return null
  }
  const publicId = item.posterImg
  return (
    <ImagePlaceholder width={width} height={Poster.calcHeight(width)} disabled={!publicId}>
      <Poster alt={item.title} width={width} {...props} publicId={publicId} />
      {item.paymentModel && premiumTitle && (
        <PosterPremiumOfferTitle item={item} paymentModelConfig={props.paymentModels} />
      )}
    </ImagePlaceholder>
  )
}

FilmPoster.calcHeight = Poster.calcHeight

FilmPoster.propTypes = {
  item: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
}
