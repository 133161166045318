import { useButtonHandlers } from '../../../../elements/controls/index.js'
import { getFestivalFilmStatus } from '../../../festival/getters.js'
import { isViewable, needsTVODPurchase, userMayView } from '../../viewStatus.js'

export const useWatchButtons = ({ isOffline, isDownloadAvailable, film, isRented }) => {
  const {
    authStatus: { hasSignedIn, hasSubscription },
  } = useButtonHandlers()

  const userMayPlay = userMayView(film, hasSubscription, isOffline, isRented)

  const needToPurchase = needsTVODPurchase(film, isRented)

  const isAvailable = isViewable(film)
  const isSeries = film.type === 'series'
  const { isLive, isFestivalFilm, isFinished } = getFestivalFilmStatus(film)
  const isLiveOrNotFestival = isLive || !isFestivalFilm

  const buttonsVisible = {
    play: isAvailable && isLiveOrNotFestival && userMayPlay && !isSeries,
    purchase: needToPurchase,
    playSeries: isSeries,
    playTrailer: !isOffline,
    reminder: !isAvailable && !isFestivalFilm,
    download: isDownloadAvailable && userMayPlay,
    recommend: !isOffline,
    groupWatch:
      (userMayPlay && !film.unavailableInRegion && !isOffline) ||
      (hasSubscription && isFestivalFilm && !isFinished),
    watchList: (userMayPlay || (isFestivalFilm && hasSignedIn)) && !isOffline,
    signUp: !hasSubscription && !isOffline,
    signIn: !hasSubscription && !isOffline,
  }

  return {
    buttonsVisible,
    visibleButtonsCount: Object.values(buttonsVisible).filter(Boolean).length,
  }
}
