import { View } from 'react-native'
import styled from 'styled-components'
import { ActivityIndicator } from '../../../elements/status'

const Footer = styled(View)`
  background: ${(props) => props.theme.colors.background};
  flex: 1;
  justify-content: center;
  padding: ${(props) => props.theme.spacing.md}px;
  height: 110px;
`

export const LoadingFooter = ({ style }) => (
  <Footer style={style}>
    <ActivityIndicator scale={1.5} />
  </Footer>
)
