/* eslint-disable no-console */
import { View } from 'react-native'
import Svg, { Circle } from 'react-native-svg'
import styled, { useTheme } from 'styled-components'
import PropTypes from 'prop-types'
import { Icon } from '../../images/Icon'

const ContentWrapper = styled(View)`
  justify-content: center;
  align-items: center;
`

const PositionAbsolute = styled(View)`
  position: absolute;
`

export const CircularProgress = ({ progress = 100, centerIcon, size, color, style }) => {
  const theme = useTheme()
  const center = size / 2
  const strokeWidth = size * 0.13
  const radius = size / 2 - strokeWidth / 2
  const circumference = 2 * Math.PI * radius
  const strokeDashOffset = radius * Math.PI * 2 * ((100 - progress) / 100)

  return (
    <ContentWrapper style={{ ...style, width: size, height: size }}>
      {centerIcon && (
        <PositionAbsolute>
          <Icon name={centerIcon} size={size * 0.6} color={color} />
        </PositionAbsolute>
      )}
      <Svg size={size} viewBox={`0 0 ${size} ${size}`}>
        <Circle
          cx={center}
          cy={center}
          r={radius}
          stroke={theme.colors.grayLighte}
          strokeWidth={strokeWidth}
          fill="none"
        />

        <Circle
          stroke={color ? theme.colors[color] || color : theme.colors.text}
          fill="none"
          cx={center}
          cy={center}
          r={radius}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={strokeDashOffset}
          transform={`rotate(-90, ${size / 2}, ${size / 2})`}
          {...{ strokeWidth }}
        />
      </Svg>
    </ContentWrapper>
  )
}

CircularProgress.propTypes = {
  progress: PropTypes.number,
  scale: PropTypes.number,
  size: PropTypes.number,
  onPress: PropTypes.func,
  centerIcon: PropTypes.string,
}
