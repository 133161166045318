import { defineMessages } from 'react-intl'

export default defineMessages({
  loadingContent: {
    id: 'app.error.loadingContent',
    defaultMessage: 'There was an error while loading this content, please try again later',
  },
  loadingFilms: {
    id: 'app.error.loadingFilms',
    defaultMessage: 'There was an error while loading films',
  },
  loadingActors: {
    id: 'app.error.loadingActors',
    defaultMessage: 'There was an error while loading actors',
  },
  loadingSearch: {
    id: 'app.error.loadingSearch',
    defaultMessage: 'There was an error retrieving search results',
  },
  groupWatchList: {
    id: 'app.error.groupWatchList',
    defaultMessage: 'There was an error retrieving screening parties',
  },

  oops404: {
    id: 'app.error.oops404',
    defaultMessage: "Oops, we couldn't find this {item}.",
  },
  tryVisit: {
    id: 'app.error.tryVisit',
    defaultMessage: 'Try visiting our {fallbackLink} instead.',
  },
})
