import { defineMessages } from 'react-intl'

export default defineMessages({
  cards: {
    id: 'app.profile.ProfilePage.cards',
    defaultMessage: 'Cards',
  },
  addCard: {
    id: 'app.profile.ProfilePage.addCard',
    defaultMessage: 'Add a Card',
  },
  adding: {
    id: 'app.profile.ProfilePage.adding',
    defaultMessage: 'Adding…',
  },
  cardholder: {
    id: 'app.profile.ProfilePage.cardholder',
    defaultMessage: 'Cardholder',
  },
  cardNo: {
    id: 'app.profile.ProfilePage.cardNo',
    defaultMessage: 'Card no',
  },
  expires: {
    id: 'app.profile.ProfilePage.expires',
    defaultMessage: 'Expires',
  },
  defaultCard: {
    id: 'app.profile.ProfilePage.defaultCard',
    defaultMessage: 'Default Card',
  },
  makeDefault: {
    id: 'app.profile.ProfilePage.makeDefault',
    defaultMessage: 'Make Default',
  },
  removeCard: {
    id: 'app.profile.ProfilePage.removeCard',
    defaultMessage: 'Remove',
  },
  noCards: {
    id: 'app.profile.ProfilePage.noCards',
    defaultMessage:
      'You have not added any cards to your account. You can add a card now or when subscribing to one of our packages.',
  },
})
