import { View } from 'react-native'
import styled from 'styled-components'
import { Text } from '../../../../elements/typography'
import { media } from '../../../../style/media'

const Values = styled(Text)`
  font-size: ${(props) => props.theme.fontSizes.xsmall}px;
  color: ${(props) => props.theme.colors.whiteFaded};

  ${media.sm`
    font-size: ${(props) => props.theme.fontSizes.small}px;
  `}
`

const Keys = styled(Values)`
  margin-right: ${(props) => props.theme.spacing.md}px;
`

const Row = styled(View)`
  flex-direction: row;
  margin-bottom: ${(props) => props.theme.spacing.sm}px;
`

const LeftSection = styled(View)`
  width: ${(props) => props.width}px;
  ${media.sm`
    padding-left: ${(props) => props.theme.spacing.sm}px;
  `}
`

const RightSection = styled(View)`
  padding: ${(props) => `0 ${props.theme.spacing.sm}px`};
`

const CreditsBlock = ({ label, values, keysWidth, valuesWidth }) => (
  <Row>
    <LeftSection width={keysWidth}>
      <Keys>{label}</Keys>
    </LeftSection>
    <RightSection style={{ width: valuesWidth }}>
      <Values>{values.join(', ')}</Values>
    </RightSection>
  </Row>
)

export const CreditsMobile = ({ credits, ...props }) => (
  <>
    {credits.map((creditsType) => (
      <CreditsBlock
        key={creditsType.type}
        label={creditsType.label}
        values={creditsType.values}
        {...props}
      />
    ))}
  </>
)
