import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'

const USER_FILM_SLUGS = gql`
  query UserFilmSlugsQuery {
    userFilmSlugs @rest(type: "FilmSlugGroup", endpoint: "v2", path: "member/film-slugs") {
      slug
      items @type(name: "FilmSlugList") {
        slug
        items
      }
    }
  }
`

const useUserFilmSlugsQuery = () => useQuery(USER_FILM_SLUGS)

const useUserFilmSlugs = (listSlug) => {
  const { data } = useUserFilmSlugsQuery()
  return useMemo(() => {
    const list = data?.userFilmSlugs?.items.find((list) => list.slug === listSlug)?.items ?? []
    return list.reduce((result, slug) => {
      result[slug] = true
      return result
    }, {})
  }, [listSlug, data])
}

/**
 * Get the list of film slugs the user has set reminders for.
 * While loading an empty list will be returned.
 */
export const useUserReminderSlugs = () => useUserFilmSlugs('reminders')

/**
 * Get the list of film slugs on the user's watch list.
 * While loading an empty list will be returned.
 */
export const useUserWatchListSlugs = () => useUserFilmSlugs('watch-list')

/**
 * Get the list of film slugs on the user's rented film.
 * While loading an empty list will be returned.
 */
export const useUserRentedFilmSlugs = () => useUserFilmSlugs('rented')

const readUserFilmSlugsSet = (client, listSlug) => {
  const result = client.readQuery({ query: USER_FILM_SLUGS })
  const items = result?.userFilmSlugs.items.find((l) => l.slug === listSlug)?.items
  return items && new Set(items)
}

const writeUserFilmSlugsSet = (client, listSlug, listSet) => {
  const userFilmSlugs = client.readQuery({ query: USER_FILM_SLUGS })?.userFilmSlugs
  client.writeQuery({
    query: USER_FILM_SLUGS,
    data: {
      userFilmSlugs: {
        ...userFilmSlugs,
        items: userFilmSlugs.items.map((list) =>
          list.slug !== listSlug ? list : { ...list, items: [...listSet] },
        ),
      },
    },
  })
}

export const addToUserFilmSlugsQuery = (client, listSlug, filmSlug) => {
  const filmSlugsSet = readUserFilmSlugsSet(client, listSlug)
  if (filmSlugsSet) {
    filmSlugsSet.add(filmSlug)
    writeUserFilmSlugsSet(client, listSlug, filmSlugsSet)
  }
}

export const removeFromUserFilmSlugsQuery = (client, listSlug, filmSlug) => {
  const filmSlugsSet = readUserFilmSlugsSet(client, listSlug)
  if (filmSlugsSet) {
    filmSlugsSet.delete(filmSlug)
    writeUserFilmSlugsSet(client, listSlug, filmSlugsSet)
  }
}
