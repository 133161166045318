import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import {
  IconButtonContainer,
  IconLabel,
  IconLabelWrapper,
} from '../../../../../elements/controls/IconButton/IconButton.js'
import { CircularProgressIcon } from '../../../../../elements/status/CircularProgress/CircularProgressIcon.js'
import messages from '../../../../downloads/messages.js'

export const DownloadQueuedButton = ({ onPress, progress = 0 }) => (
  <IconButtonContainer onPress={onPress}>
    <CircularProgressIcon progress={progress} centerIcon="hourglass-empty" />
    <IconLabelWrapper>
      <IconLabel>
        <FormattedMessage {...messages.queued} />
      </IconLabel>
    </IconLabelWrapper>
  </IconButtonContainer>
)

DownloadQueuedButton.propTypes = {
  onPress: PropTypes.func,
}
