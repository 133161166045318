import { View } from 'react-native'
import styled from 'styled-components'
import times from 'lodash/times'
import { calcIconSize } from '../../../elements/images/Icon'
import { useMediaIs } from '../../../style/media'
import { StarIcon } from './StarIcon'

const Container = styled(View)`
  width: ${(props) => calcIconSize(props.theme, props.scale) * props.stars}px;
  height: ${(props) => calcIconSize(props.theme, props.scale)}px;
`

const Stars = styled(View)`
  flex-direction: row;
  width: ${(props) => calcIconSize(props.theme, props.scale) * props.stars}px;
`

// Android TV needs a background color for overflow hidden to work for some reason
const RatedStars = styled(View)`
  background-color: transparent;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: ${(props) => calcIconSize(props.theme, props.scale) * props.rating}px;
`

export const StarRating = ({ stars = 5, rating = 0, scale = 1, style }) => {
  const isTablet = useMediaIs('sm')
  const finalScale = (isTablet ? 1 : 0.6) * scale
  return (
    <Container stars={stars} scale={finalScale} style={style}>
      <Stars stars={stars} scale={finalScale}>
        {times(stars, (n) => (
          <StarIcon key={n} name="star" scale={finalScale} color="gray" />
        ))}
      </Stars>
      <RatedStars rating={rating} scale={finalScale}>
        <Stars stars={stars} scale={finalScale}>
          {times(stars, (n) => (
            <StarIcon key={n} name="star" scale={finalScale} color="ratedStars" />
          ))}
        </Stars>
      </RatedStars>
    </Container>
  )
}

export default StarRating
