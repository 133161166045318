import { Pressable } from 'react-native'
import styled from 'styled-components'
import { useButtonHandlers } from '../../../elements/controls'
import Image from '../../../elements/images/Image/Image'

export const fixedLogoBoxHeight = 36

const ChannelLogo = styled(Pressable)`
  width: 100%;
  align-items: center;
  justify-content: center;
`

const logoDimensions = { width: 421, height: 137 }

export const FilmListChannelLogo = ({ channel }) => {
  const { handlers } = useButtonHandlers()

  if (!channel) return null

  const { logoImg, slug } = channel

  const aspectRatio = logoDimensions.width / logoDimensions.height

  const imgWidth = fixedLogoBoxHeight * aspectRatio

  return (
    <ChannelLogo onPress={() => handlers.viewDetails({ slug, type: 'channel' })}>
      <Image
        width={imgWidth}
        height={fixedLogoBoxHeight}
        publicId={logoImg}
        crop="limit"
        resizeMode="contain"
      />
    </ChannelLogo>
  )
}
