import { View } from 'react-native'
import styled from 'styled-components'
import { userMayView } from '@flix/common/domain/content/viewStatus'
import { useButtonHandlers } from '@flix/common/elements/controls'
import { useMediaIs } from '@flix/common/style/media'
import { LineupPanelButtonsDesktop } from './LineupPanelButtonsDesktop'
import { LineupPanelButtonsMobile } from './LineupPanelButtonsMobile'

const ButtonRow = styled(View)`
  flex-direction: row;
  align-self: flex-start;
`

export const LineupPanelButtons = ({ film, isLive, ...props }) => {
  const { handlers, authStatus } = useButtonHandlers()
  const isPlayable = userMayView(film, authStatus.hasSubscription)
  const atLeastTablet = useMediaIs('sm')

  const ButtonsComponent = atLeastTablet ? LineupPanelButtonsDesktop : LineupPanelButtonsMobile

  return (
    <ButtonRow>
      <ButtonsComponent
        {...props}
        film={film}
        isPlayable={isPlayable && isLive}
        playTrailer={() => handlers.playTrailer(film)}
        playFeature={() => handlers.playFeature(film)}
        addToWatchList={() => handlers.addToWatchList(film)}
        removeFromWatchList={() => handlers.removeFromWatchList(film)}
      />
    </ButtonRow>
  )
}
