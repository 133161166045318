import { memo } from 'react'
import styled from 'styled-components'
import { TouchableNoFeedback } from '../../../elements/controls'
import Poster from '../../../elements/images/Poster/Poster'
import { cursorPointer } from '../../../style/webStyles'
import { ActorPosterNamed } from '../../actors/ActorPosterNamed/ActorPosterNamed.js'
import { PosterAd } from '../../ads/nativeAds/PosterAd.js'
import { FilmPoster } from '../../content/FilmPoster/FilmPoster'

const calcHeight = (width, theme) => {
  const gutter = theme.spacing.sm
  return Poster.calcHeight(width - gutter) + gutter
}

const Touchable = styled(TouchableNoFeedback)`
  padding-left: ${(props) => props.theme.spacing.sm / 2}px;
  padding-right: ${(props) => props.theme.spacing.sm / 2}px;
  padding-bottom: ${(props) => props.theme.spacing.sm}px;
  ${cursorPointer};
`

const PosterContentBase = ({ item, theme, width, onChosen, premiumTitle }) => {
  const Poster = item.type === 'person' ? ActorPosterNamed : FilmPoster
  return (
    <Touchable onPress={() => onChosen(item)}>
      {item.type === 'ad' ? (
        <PosterAd
          zone={item.zone}
          placementXs={item.placementMobile}
          placementSm={item.placement}
          index={item.index}
          width={width - theme.spacing.sm}
        />
      ) : (
        <Poster item={item} width={width - theme.spacing.sm} premiumTitle={premiumTitle} />
      )}
    </Touchable>
  )
}

export const PosterContent = memo(PosterContentBase)

PosterContent.calcHeight = calcHeight
PosterContent.getNumColumns = ({ sm, md }) => (md ? 5 : sm ? 4 : 3)
