import { memo } from 'react'
import { Pressable, View } from 'react-native'
import styled, { css } from 'styled-components'
import { cursorPointer } from '../../../style/webStyles'
import { FilmLandscapePoster } from '../../content/FilmLandscapePoster/FilmLandscapePoster'
import {
  FilmListChannelLogo,
  fixedLogoBoxHeight,
} from '../../content/FilmLandscapePoster/FilmListChannelLogo'

const calcHeight = (width, theme) => {
  const gutter = theme.spacing.sm
  return FilmLandscapePoster.calcHeight(width - gutter) + gutter + fixedLogoBoxHeight
}

const containerStyle = css`
  padding-left: ${(props) => props.theme.spacing.sm / 2}px;
  padding-right: ${(props) => props.theme.spacing.sm / 2}px;
  padding-bottom: ${(props) => props.theme.spacing.sm}px;
`

const PressableContainer = styled(Pressable)`
  ${containerStyle};
  ${cursorPointer};
`

const TOP_STREAMING_NOW = 'streaming-now'

export const PosterLogoLandscapeContent = memo(
  ({ listSlug, item, theme, width, onChosen, ...props }) => {
    const isTopStreamingNowList = listSlug === TOP_STREAMING_NOW

    return (
      <View>
        {/* Top Streaming Now list directs to the channel page when chosen */}
        <PressableContainer
          onPress={() =>
            onChosen(isTopStreamingNowList ? { ...item.channel, type: 'channel' } : item)
          }
        >
          <FilmLandscapePoster
            {...props}
            item={item}
            width={width - theme.spacing.sm}
            isTopStreaming={isTopStreamingNowList}
          />
        </PressableContainer>
        <FilmListChannelLogo channel={item.channel} width={width - theme.spacing.sm} />
      </View>
    )
  },
)

PosterLogoLandscapeContent.calcHeight = calcHeight

PosterLogoLandscapeContent.getNumColumns = ({ sm, md }) => (md ? 5 : sm ? 4 : 3)
