import { forwardRef } from 'react'
import { TouchableOpacity, View } from 'react-native'
import styled from 'styled-components'
import { Text } from '../../typography/index.js'

const Touchable = styled(TouchableOpacity)`
  flex-direction: row;
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
  margin-bottom: ${(props) => props.theme.spacing[props.mb ? props.mb : 'smedium']}px;
`

const Radio = styled(View)`
  border-width: 2px;
  border-color: ${({ checked, color, theme }) =>
    checked ? color || theme.colors.primaryDark : theme.colors.grayLighte};
  border-radius: ${(props) => props.theme.spacing.larger - 3}px;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.theme.spacing.larger - 3}px;
  height: ${(props) => props.theme.spacing.larger - 3}px;
  margin-right: ${(props) => props.theme.spacing.small}px;
`

const Dot = styled(View)`
  background-color: ${({ checked, color, theme }) =>
    checked ? color || theme.colors.primary : theme.colors.text};
  border-radius: ${(props) => props.theme.spacing.larger - 13}px;
  width: ${(props) => props.theme.spacing.larger - 13}px;
  height: ${(props) => props.theme.spacing.larger - 13}px;
`

const RadioLabel = styled(View)`
  flex: 1;
`

const a11yState = (checked, disabled) => {
  const states = [checked ? 'checked' : 'unchecked']
  disabled && states.push('disabled')
  return states
}

export const RadioButton = forwardRef(
  ({ checked, color, children, label, disabled, ...props }, ref) => (
    <Touchable
      {...props}
      activeOpacity={1}
      disabled={disabled}
      accessibilityRole="radio"
      accessibilityStates={a11yState(checked, disabled)}
    >
      <Radio color={color} checked={checked}>
        {checked && <Dot color={color} checked={checked} ref={ref} />}
      </Radio>
      <RadioLabel>{children || <Text>{label}</Text>}</RadioLabel>
    </Touchable>
  ),
)
