import { View } from 'react-native'
import styled from 'styled-components'
import StarRating from '@flix/common/domain/content/StarRating/StarRating'
import BackgroundImage from '@flix/common/elements/images/BackgroundImage/BackgroundImage'
import Poster from '@flix/common/elements/images/Poster/Poster'
import { Row } from '@flix/common/elements/layout/Grid'
import { H3, Text } from '@flix/common/elements/typography'
import { useLayout } from '@flix/common/hooks'
import { media, useMediaIs } from '@flix/common/style/media'
import { useFestivalCountdownStatus } from '../../hooks/useFestivalCountdownStatus'
import { LineupPanelButtons } from './LineupPanelButtons'
import { ScreeningStatus } from './ScreeningStatus'

const Content = styled(View)`
  flex: 1;
  flex-direction: row;
  align-items: center;
`

const Background = styled(BackgroundImage)`
  ${media.sm`
    background-color: ${(props) => props.theme.colors.grayDarker};
    padding: ${(props) => props.theme.spacing.lg}px;
`}
`

const RightContent = styled(View)`
  height: 100%;
  flex: 1;
  padding: 0 ${(props) => props.theme.spacing.sm}px;
  justify-content: space-between;

  ${media.sm`
    justify-content: flex-start;
    padding: 0 ${(props) => props.theme.spacing.md}px;
  `}
`

const Top = styled(View)`
  ${media.sm`
    margin-bottom: ${(props) => props.theme.spacing.md}px;
  `}
`

const TitleAndRating = styled(View)`
  margin-bottom: ${(props) => props.theme.spacing.sm}px;

  ${media.sm`
    flex-direction: row;
  `}
`

const FilmTitle = styled(H3)`
  margin-bottom: 0;
  margin-right: ${(props) => props.theme.spacing.sm}px;
  line-height: ${(props) => props.theme.fontSizes.large * 1.2}px;
  font-size: ${(props) => props.theme.fontSizes.large}px;
  ${media.sm`
    line-height: ${(props) => props.theme.fontSizes.xlarge * 1.2}px;
    font-size: ${(props) => props.theme.fontSizes.xlarge}px;
  `}
`

const ShortDescription = styled(Text)`
  color: ${(props) => props.theme.colors.whiteFaded};
  font-size: ${(props) => props.theme.fontSizes.xxsmall}px;
  margin-bottom: ${(props) => props.theme.spacing.xs}px;

  ${media.sm`
    margin-bottom: ${(props) => props.theme.spacing.md}px;
    font-size: ${(props) => props.theme.fontSizes.medium}px;
    width: 50%;
  `}

  ${media.md`
    font-size: ${(props) => props.theme.fontSizes.large}px;
  `}
`

const backgroundGradient = { x: 0.8, strength: 30 }

export const FestivalLineupPanel = ({ film, width, isOnWatchList, isFullySubscribed }) => {
  const { onLayout, height: contentHeight } = useLayout()
  const atLeastXS = useMediaIs('xs')
  const atLeastTablet = useMediaIs('sm')
  const countdownStatus = useFestivalCountdownStatus(film)

  return (
    <Row width={width}>
      <Background
        bgImgId={!atLeastTablet ? null : film.promoPanelBg}
        width={width}
        height={Math.round(width * (atLeastTablet ? 0.4 : 0.5))}
        gradient={backgroundGradient}
      >
        <Content onLayout={onLayout}>
          {!!contentHeight && (
            <>
              <Poster publicId={film.posterImg} width={contentHeight / 1.5} />

              <RightContent>
                <Top>
                  <TitleAndRating>
                    <FilmTitle numberOfLines={1}>{film.title}</FilmTitle>
                    <StarRating rating={film.rating} scale={0.8} />
                  </TitleAndRating>
                  {atLeastXS && (
                    <ShortDescription numberOfLines={3}>{film.shortDescription}</ShortDescription>
                  )}
                  <ScreeningStatus {...countdownStatus} film={film} />
                </Top>

                <LineupPanelButtons
                  film={film}
                  isOnWatchList={isOnWatchList}
                  isFullySubscribed={isFullySubscribed}
                  isLive={countdownStatus.isLive}
                />
              </RightContent>
            </>
          )}
        </Content>
      </Background>
    </Row>
  )
}
