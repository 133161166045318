"use strict";

var _excluded = ["loading", "data", "fetchMore", "variables"];

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import { useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { identity } from 'lodash';
import { formatNFTFilterValues } from './formatNFTFilterValues';
var ALL_NFT_ITEMS = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "AllNFTItemsQuery"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "page"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      },
      "directives": []
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "limit"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      },
      "directives": []
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "query"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      },
      "directives": []
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "priceMin"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      },
      "directives": []
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "priceMax"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      },
      "directives": []
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "films"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      },
      "directives": []
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "categories"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      },
      "directives": []
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "tags"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      },
      "directives": []
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "fieldNameNormalizer"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "any"
        }
      },
      "directives": []
    }],
    "directives": [],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "nftPublishedItems"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "page"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "page"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "limit"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "limit"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "query"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "query"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "priceMin"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "priceMin"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "priceMax"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "priceMax"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "films"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "films"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "categories"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "categories"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "tags"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "tags"
            }
          }
        }],
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "connection"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "key"
            },
            "value": {
              "kind": "StringValue",
              "value": "nftPublishedItems",
              "block": false
            }
          }, {
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "filter"
            },
            "value": {
              "kind": "ListValue",
              "values": [{
                "kind": "StringValue",
                "value": "query",
                "block": false
              }, {
                "kind": "StringValue",
                "value": "films",
                "block": false
              }, {
                "kind": "StringValue",
                "value": "categories",
                "block": false
              }, {
                "kind": "StringValue",
                "value": "tags",
                "block": false
              }, {
                "kind": "StringValue",
                "value": "priceMin",
                "block": false
              }, {
                "kind": "StringValue",
                "value": "priceMax",
                "block": false
              }]
            }
          }]
        }, {
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "rest"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "type"
            },
            "value": {
              "kind": "StringValue",
              "value": "NFTAllItemsList",
              "block": false
            }
          }, {
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "endpoint"
            },
            "value": {
              "kind": "StringValue",
              "value": "v2",
              "block": false
            }
          }, {
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "path"
            },
            "value": {
              "kind": "StringValue",
              "value": "nft/items?page={args.page}&limit={args.limit}&query={args.query}&price_min={args.priceMin}&price_max={args.priceMax}&{args.films}&{args.categories}&{args.tags}",
              "block": false
            }
          }, {
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "fieldNameNormalizer"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "fieldNameNormalizer"
              }
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "slug"
            },
            "arguments": [],
            "directives": []
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "listType"
            },
            "arguments": [],
            "directives": []
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "items"
            },
            "arguments": [],
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "type"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "name"
                },
                "value": {
                  "kind": "StringValue",
                  "value": "NFTItem",
                  "block": false
                }
              }]
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                },
                "arguments": [],
                "directives": []
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "published"
                },
                "arguments": [],
                "directives": []
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "frozen"
                },
                "arguments": [],
                "directives": []
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "listed"
                },
                "arguments": [],
                "directives": []
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "minted"
                },
                "arguments": [],
                "directives": []
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "listingId"
                },
                "arguments": [],
                "directives": []
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "listPrice"
                },
                "arguments": [],
                "directives": []
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "soldPrice"
                },
                "arguments": [],
                "directives": []
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "ownerProfileId"
                },
                "arguments": [],
                "directives": []
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "metadata"
                },
                "arguments": [],
                "directives": [{
                  "kind": "Directive",
                  "name": {
                    "kind": "Name",
                    "value": "type"
                  },
                  "arguments": [{
                    "kind": "Argument",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    },
                    "value": {
                      "kind": "StringValue",
                      "value": "NFTItemMetadata",
                      "block": false
                    }
                  }]
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "image"
                    },
                    "arguments": [],
                    "directives": []
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    },
                    "arguments": [],
                    "directives": []
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "external_item_title"
                    },
                    "arguments": [],
                    "directives": []
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "external_item_slug"
                    },
                    "arguments": [],
                    "directives": []
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }],
  "loc": {
    "start": 0,
    "end": 1260,
    "source": {
      "body": "\n  query AllNFTItemsQuery(\n    $page: Int!\n    $limit: Int!\n    $query: String!\n    $priceMin: String!\n    $priceMax: String!\n    $films: String!\n    $categories: String!\n    $tags: String!\n    $fieldNameNormalizer: any\n  ) {\n    nftPublishedItems(\n      page: $page\n      limit: $limit\n      query: $query\n      priceMin: $priceMin\n      priceMax: $priceMax\n      films: $films\n      categories: $categories\n      tags: $tags\n    )\n      @connection(\n        key: \"nftPublishedItems\"\n        filter: [\"query\", \"films\", \"categories\", \"tags\", \"priceMin\", \"priceMax\"]\n      )\n      @rest(\n        type: \"NFTAllItemsList\"\n        endpoint: \"v2\"\n        path: \"nft/items?page={args.page}&limit={args.limit}&query={args.query}&price_min={args.priceMin}&price_max={args.priceMax}&{args.films}&{args.categories}&{args.tags}\"\n        fieldNameNormalizer: $fieldNameNormalizer\n      ) {\n      slug\n      listType\n      items @type(name: \"NFTItem\") {\n        id\n        published\n        frozen\n        listed\n        minted\n        listingId\n        listPrice\n        soldPrice\n        ownerProfileId\n        metadata @type(name: \"NFTItemMetadata\") {\n          image\n          name\n          external_item_title\n          external_item_slug\n        }\n      }\n    }\n  }\n",
      "name": "GraphQL request",
      "locationOffset": {
        "line": 1,
        "column": 1
      }
    }
  }
};
var emptyItems = [];

var getItems = function getItems(data) {
  var _data$nftPublishedIte;

  return (_data$nftPublishedIte = data === null || data === void 0 ? void 0 : data.nftPublishedItems.items) !== null && _data$nftPublishedIte !== void 0 ? _data$nftPublishedIte : emptyItems;
};

export var usePublishedNFTItemsQuery = function usePublishedNFTItemsQuery(filter) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : undefined;

  var _useQuery = useQuery(ALL_NFT_ITEMS, _objectSpread(_objectSpread({
    // To ensure we get up-to-date results, this list is refetched
    fetchPolicy: 'cache-and-network'
  }, options), {}, {
    notifyOnNetworkStatusChange: true,
    variables: _objectSpread(_objectSpread({}, formatNFTFilterValues(filter)), {}, {
      // Override common top-level normalizer (for API v1) to maintain snake_case in the field names
      fieldNameNormalizer: identity
    })
  })),
      loading = _useQuery.loading,
      data = _useQuery.data,
      fetchMore = _useQuery.fetchMore,
      variables = _useQuery.variables,
      rest = _objectWithoutProperties(_useQuery, _excluded);

  return _objectSpread(_objectSpread({}, rest), {}, {
    loading: loading,
    data: data,
    onFetchMore: useCallback(function () {
      return (// If the last page is a full page, then there may be more items to fetch
        !loading && getItems(data).length % variables.limit === 0 && fetchMore({
          variables: {
            page: Math.floor(getItems(data).length / variables.limit) + 1
          },
          updateQuery: function updateQuery(prev, _ref) {
            var fetchMoreResult = _ref.fetchMoreResult;
            return !fetchMoreResult ? prev : _objectSpread(_objectSpread({}, prev), {}, {
              nftPublishedItems: _objectSpread(_objectSpread({}, prev.nftPublishedItems), {}, {
                items: [].concat(_toConsumableArray(getItems(prev)), _toConsumableArray(getItems(fetchMoreResult)))
              })
            });
          }
        })
      );
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading, getItems(data).length, variables.limit, fetchMore])
  });
};