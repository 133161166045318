import { TouchableOpacity, TouchableWithoutFeedback } from 'react-native'
import styled, { useTheme } from 'styled-components'
import PropTypes from 'prop-types'
import { FadingArrowCarousel } from '@flix/common/elements/carousels/FadingArrowCarousel/FadingArrowCarousel'
import { Backdrop, CloseButton, Modal } from '@flix/common/elements/layout/Modal'
import { useKeyboard } from '@flix/common/hooks/useKeyboard/useKeyboard'
import { useDimensions } from '../../../hooks'
import Icon from '../Icon'
import { ResponsiveImage } from '../ResponsiveImage/ResponsiveImage'

const ModalBackdrop = styled(Backdrop)`
  padding: ${(props) => props.theme.spacing.large}px 0;
`

const ModalCloseButton = styled(CloseButton)`
  top: ${(props) => props.theme.spacing.large}px;
`

export const ImageCarouselFullScreen = ({
  shown,
  onRequestClose,
  items = [],
  aspectRatio,
  ...props
}) => {
  const theme = useTheme()
  const { height } = useDimensions()
  const maxHeight = height - theme.spacing.large * 2

  useKeyboard({
    escape: onRequestClose,
  })

  return (
    <Modal visible={shown} transparent onRequestClose={onRequestClose}>
      <TouchableWithoutFeedback onPress={onRequestClose}>
        <ModalBackdrop dark>
          <FadingArrowCarousel
            keyboardNavEnabled
            gradientOpacity={0}
            slidesToShow={1}
            slidesToScroll={1}
            {...props}
            items={items}
            style={{ width: '100%' }}
            renderItem={(publicId) => (
              <ResponsiveImage
                key={publicId}
                aspectRatio={aspectRatio}
                maxHeight={maxHeight}
                publicId={publicId}
              />
            )}
          />

          <ModalCloseButton scale={0.75}>
            <TouchableOpacity onPress={onRequestClose}>
              <Icon name="close" scale={0.75} />
            </TouchableOpacity>
          </ModalCloseButton>
        </ModalBackdrop>
      </TouchableWithoutFeedback>
    </Modal>
  )
}

ImageCarouselFullScreen.propTypes = {
  shown: PropTypes.bool,
  items: PropTypes.array.isRequired,
  aspectRatio: PropTypes.number.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  initialSlide: PropTypes.number,
  onIndexChange: PropTypes.func,
}
