import { createContext, useContext } from 'react'

/**
 * The purpose of this context is to mount the `useNetInfo` hook once
 * and reuse the values throughout the app. Since the connectivity check is
 * async, there is always an initial render where the connection status is
 * not known which causes problems when remounting this on each navigation.
 *
 * It also allows doing network checks in common components which will always
 * appear connected on platforms not supporting this context.
 */
export const NetworkInfoContext = createContext({ isConnected: true, type: 'unknown' })

export const useNetworkInfo = () => useContext(NetworkInfoContext)
