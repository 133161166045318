import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import {
  IconButtonContainer,
  IconLabel,
  IconLabelWrapper,
} from '../../../../../elements/controls/IconButton/IconButton.js'
import { CircularProgressIcon } from '../../../../../elements/status/index.js'
import messages from '../../../../downloads/messages.js'

export const DownloadProgressButton = ({ onPress, progress = 0, isPaused }) => (
  <IconButtonContainer onPress={onPress}>
    <CircularProgressIcon progress={progress} centerIcon={isPaused ? 'pause' : undefined} />
    <IconLabelWrapper>
      <IconLabel>
        <FormattedMessage
          numberOfLines={1}
          {...(isPaused ? messages.paused : messages.downloading)}
        />
      </IconLabel>
    </IconLabelWrapper>
  </IconButtonContainer>
)

DownloadProgressButton.propTypes = {
  onPress: PropTypes.func,
  progress: PropTypes.number,
}
