import { memo } from 'react'
import { View } from 'react-native'
import styled, { useTheme } from 'styled-components'
import { useButtonHandlers } from '../../../../elements/controls'
import { IconButton } from '../../../../elements/controls/IconButton/IconButton.js'
import { media } from '../../../../style/media.js'
import { FeatureButton, ReminderButton, TrailerButton } from '../../WatchButtons/index.js'
import messages from '../../messages.js'
import { userMayView } from '../../viewStatus'

const ButtonRow = styled(View)`
  flex-direction: row;
  justify-content: space-evenly;

  ${media.sm`
    justify-content: space-between;
    margin-top: auto;
    max-width: 270px;
  `};
`

export const PromoFilmButtons = memo(({ film, reminders }) => {
  const theme = useTheme()
  const fontSize = 'small'
  const iconSize = theme.spacing.large
  const { handlers, authStatus } = useButtonHandlers()
  const userMayPlay = userMayView(film, authStatus.hasSubscription)
  const isOnReminders = reminders?.[film.slug] || false

  return (
    <ButtonRow>
      {userMayPlay && (
        <FeatureButton
          textDefinesWidth
          userMayPlay={userMayPlay}
          size={iconSize}
          fontSize={fontSize}
          onPress={() => handlers.playFeature(film)}
        />
      )}
      <TrailerButton
        textDefinesWidth
        size={iconSize}
        fontSize={fontSize}
        onPress={() => handlers.playTrailer(film)}
      />
      <IconButton
        textDefinesWidth
        size={iconSize}
        fontSize={fontSize}
        name="theaters"
        intlMsg={messages.viewDetails}
        onPress={() => handlers.viewDetails(film)}
      />
      {!userMayPlay && (
        <ReminderButton
          disabled={!reminders}
          style={!reminders ? { opacity: 0 } : null}
          textDefinesWidth
          size={iconSize}
          fontSize={fontSize}
          film={film}
          isReminderSet={isOnReminders}
          onPress={isOnReminders ? handlers.cancelReminder : handlers.setReminder}
          cancelMsg={messages.reminderSet}
        />
      )}
    </ButtonRow>
  )
})
