import React from 'react'
import { Text, View } from 'react-native'
import styled, { useTheme } from 'styled-components'
import Icon from '../../../elements/images/Icon'

const StatusView = styled(View)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  height: ${(props) => props.theme.spacing.larger - 4}px;
  padding: 0 ${(props) => props.theme.spacing.xsmall}px;
  background-color: ${(props) => props.theme.colors.white};

  border-radius: ${(props) => props.theme.spacing.xsmall}px;
  gap: ${(props) => props.theme.spacing.xxsmall}px;
`

const StatusText = styled(Text)`
  font-weight: bold;
  text-transform: uppercase;
  font-size: ${(props) => props.theme.fontSizes.xxsmall}px;
  color: ${(props) => props.color};
`

const LiveIcon = styled(Icon)`
  align-self: baseline;
`

export const LiveEventBadgeWrapper = styled(View)`
  position: absolute;
  left: ${(props) => props.theme.spacing.xsmall}px;
  bottom: ${(props) =>
    props.bottomSpace ? props.theme.spacing[props.bottomSpace] : props.theme.spacing.xsmall}px;

  ${(props) => props.top && `top: ${props.theme.spacing.xsmall}px`}
`

export const LiveEventBadge = ({ status }) => {
  const theme = useTheme()
  switch (status) {
    case 'Live':
      return (
        <StatusView>
          <LiveIcon name="live" color={theme.colors.primary} scale={0.65} />
          <StatusText color={theme.colors.primary}>Live</StatusText>
        </StatusView>
      )
    case 'Upcoming':
      return (
        <StatusView>
          <StatusText color={theme.colors.gray}>Upcoming</StatusText>
        </StatusView>
      )
    case 'OnNow':
      return (
        <StatusView>
          <LiveIcon name="live" color={theme.colors.primary} scale={0.65} />
          <StatusText color={theme.colors.primary}>On Now</StatusText>
        </StatusView>
      )
    case 'OnNext':
      return (
        <StatusView>
          <StatusText color={theme.colors.gray}>On Next</StatusText>
        </StatusView>
      )
    default:
      return null
  }
}
