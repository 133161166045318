import { useEffect } from 'react'
import noop from 'lodash/noop'
import { canUseDOM } from '../../utils/canUseDOM'

const keyMap = {
  27: 'escape',
  37: 'left',
  38: 'up',
  39: 'right',
  40: 'down',
  13: 'enter',
  8: 'back', // Backspace

  80: 'play', // p
  79: 'pause', // o
  73: 'playPause', // i
  83: 'stop', // s
  82: 'rewind', // r
  70: 'fastForward', // f
}

/**
 * Override the default browser key map (for some Smart TV platforms)
 */
export const setKeyMap = (newKeyMap) => {
  Object.assign(keyMap, newKeyMap)
}

/*
 * The DOM standard is to call event handlers in the order in which they
 * have been added. Keyboard handlers should run in the opposite order so
 * the most recently added / most deeply nested handlers can trigger first
 * and have the opportunity to override the behaviour of those added earlier.
 */
const eventHandlers = []

export const handleKeyDown = (event) => {
  for (const handlerSet of eventHandlers) {
    const handler = handlerSet[keyMap[event.keyCode]]
    if (handler) {
      event.preventDefault()
      handler()
      return // Stop propagation to earlier handlers via this hook
    }
  }
}

canUseDOM && window.addEventListener('keydown', handleKeyDown)

export const useKeyboard = (handlers) => {
  useEffect(() => {
    // Keyboard is enabled by default
    if (handlers.enabled === false) return

    eventHandlers.unshift(handlers)
    return () => {
      const index = eventHandlers.indexOf(handlers)
      if (index > -1) {
        eventHandlers.splice(index, 1)
      }
    }
  }, [handlers])
}

export const addSpatialNavEventListener = noop
export const removeSpatialNavEventListener = noop
