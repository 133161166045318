import { FormattedNumber } from 'react-intl'
import { StyleSheet, View } from 'react-native'
import styled, { useTheme } from 'styled-components'
import { LinearGradient } from '../../../elements/images/LinearGradient'
import { Text } from '../../../elements/typography'
import { usePaymentModel } from '../../../hooks/usePaymentModel/usePaymentModel'
import { media, useMediaIs } from '../../../style/media'
import { zoomScale } from '../../content/FilmLandscapePoster/FilmLandscapePoster.constants.js'

const PremiumView = styled(View)`
  position: absolute;
  bottom: ${({ mobile }) => (mobile ? '-1.2' : 0)}px;
  left: 0;
  right: 0;
`

const PremiumText = styled(Text)`
  font-size: ${(props) => props.theme.fontSizes.xxxsmall}px;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  text-align: center;
  z-index: 1;

  text-transform: uppercase;
  ${media.sm`
    font-size: ${(props) =>
      !props.zoomed ? props.theme.fontSizes.small : props.theme.fontSizes.small * zoomScale}px;
  `}
`

export const PosterPremiumOfferTitle = ({ item, zoomed }) => {
  const { paymentModelConfig = {} } = usePaymentModel()

  const theme = useTheme()
  const isMobile = !useMediaIs('sm')

  const config = premiumTitleConfig(item.paymentModel, paymentModelConfig, theme)

  if (!config) {
    return null
  }

  return (
    <PremiumView mobile={isMobile}>
      <LinearGradient
        colors={[config.colorLeft, config.colorRight]}
        locations={[0.3, 1]}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 0 }}
        style={StyleSheet.absoluteFill}
      />
      <PremiumText zoomed={zoomed}>{config.title}</PremiumText>
    </PremiumView>
  )
}

const premiumTitleConfig = (itemPaymentModel, paymentModelConfig, theme) => {
  if (itemPaymentModel.tvod && paymentModelConfig.tvod) {
    const tier =
      paymentModelConfig.tvod.items.find((item) => item.slug === itemPaymentModel.tvod[0]) ?? {}

    return {
      title: tier.paymentNote ?? defaultTvodTitle(tier),
      colorLeft: theme.colors.gradientPrimaryDark,
      colorRight: theme.colors.gradientPrimaryLight,
    }
  } else if (itemPaymentModel.svod) {
    return null
  } else if (itemPaymentModel.free) {
    return {
      title: 'Free With Ads',
      colorLeft: theme.colors.gradientGreenDark,
      colorRight: theme.colors.gradientGreenLight,
    }
  } else {
    return null
  }
}
const defaultTvodTitle = (tier) => (
  <>
    Premium Access{' '}
    {tier.currency && (
      <FormattedNumber value={(tier.price ?? 0) / 100} style="currency" currency={tier.currency} />
    )}
  </>
)
