import { defineMessages } from 'react-intl'

export default defineMessages({
  // Nav Items
  aboutNav: {
    id: 'app.festival.aboutNav',
    defaultMessage: 'About',
  },
  lineupNav: {
    id: 'app.festival.lineupNav',
    defaultMessage: 'LineUp',
  },
  awardsNav: {
    id: 'app.festival.awardsNav',
    defaultMessage: 'Awards',
  },
  myFestivalNav: {
    id: 'app.festival.myFestivalNav',
    defaultMessage: 'My Festival',
  },
  flixNav: {
    id: 'app.festival.flixNav',
    defaultMessage: 'Flix Premiere',
  },
  blogNav: {
    id: 'app.festival.blogNav',
    defaultMessage: 'Festival News',
  },

  // About

  viewLineup: {
    id: 'app.festival.viewLineup',
    defaultMessage: 'View Lineup',
  },

  // Lineup
  lineupHeading: {
    id: 'app.festival.lineupHeading',
    defaultMessage: '{festivalName} LineUp',
  },
  availableIn: {
    id: 'app.festival.availableIn',
    defaultMessage: 'Available in ',
  },
  availableFrom: {
    id: 'app.festival.availableFrom',
    defaultMessage: 'Available from',
  },
  nowShowingFor: {
    id: 'app.festival.nowShowingFor',
    defaultMessage: 'Now Showing for',
  },
  finished: {
    id: 'app.festival.finished',
    defaultMessage: 'No more screenings',
  },
  availableUntil: {
    id: 'app.festival.availableUntil',
    defaultMessage: 'Screening until ',
  },
  days: {
    id: 'app.festival.days',
    defaultMessage: '{days, number} day{days, plural, one {} other {s}}',
  },

  // Countdown
  hoursLong: {
    id: 'app.festival.hoursLong',
    defaultMessage: '{hrs, number} hour{hrs, plural, one {} other {s}}',
  },
  minutesLong: {
    id: 'app.festival.minutesLong',
    defaultMessage: '{mins, number} minute{mins, plural, one {} other {s}}',
  },
  secondsLong: {
    id: 'app.festival.secondsLong',
    defaultMessage: '{secs, number} second{secs, plural, one {} other {s}}',
  },
  hoursShort: {
    id: 'app.festival.hoursShort',
    defaultMessage: '{hrs, number} hr{hrs, plural, one {} other {s}}',
  },
  minutesShort: {
    id: 'app.festival.minutesShort',
    defaultMessage: '{mins, number} min{mins, plural, one {} other {s}}',
  },
  secondsShort: {
    id: 'app.festival.secondsShort',
    defaultMessage: '{secs, number} sec{secs, plural, one {} other {s}}',
  },

  // My Festival
  myFestival: {
    id: 'app.festival.myFestival',
    defaultMessage: 'My Festival',
  },
  watchList: {
    id: 'app.festival.watchList',
    defaultMessage: 'Watch List',
  },
  notVoted: {
    id: 'app.festival.notVoted',
    defaultMessage: 'You have not voted yet!',
  },
  awardsTitle: {
    id: 'app.festival.awardsTitle',
    defaultMessage: 'Cast Your Vote',
  },
  voteInfo: {
    id: 'app.festival.notVotedInfo',
    defaultMessage:
      "Don't forget you can vote for who you think should win at the Flix Premiere Online Film Festival, including the award for the Best Film and Best Director.\n\nIf you change your mind, you can edit your vote any time during the festival and the winners will be announced after the festival.",
  },
  voteNow: {
    id: 'app.festival.voteNow',
    defaultMessage: 'Vote Now',
  },
  editVote: {
    id: 'app.festival.editVote',
    defaultMessage: 'Edit Vote',
  },
  emptyMyFestivalHeading: {
    id: 'app.festival.emptyMyFestivalHeading',
    defaultMessage: 'Welcome to My Festival',
  },
  emptyMyFestivalDescription: {
    id: 'app.festival.emptyMyFestivalDescription',
    defaultMessage: `
    Here is the place to manage your watchlist during a festival as well as edit your selections when voting is open in the Flix Premiere Online Film Festival Awards.
    
    Visit our LineUp page to see what independent films you can enjoy during the Festival.
    `,
  },

  // Awards Page
  awardsHeading: {
    id: 'app.festival.awardsHeading',
    defaultMessage: 'Flix Premiere Online Film Festival Awards',
  },
  awardsDescription: {
    id: 'app.festival.awardsDescription',
    defaultMessage: `
    Flix Premiere are proud to award the best in independent film with our very own Audience Awards. Cast your vote for the Best Picture, Best Director and Best Cinematography, plus four different acting categories.
    
    You can vote in Pause Mode while watching a film or by going to My Festival and submitting your picks there. The winners will be announced when the festival closes.
    `,
  },
  notSubmitted: {
    id: 'app.festival.notSubmitted',
    defaultMessage: 'Not Submitted',
  },

  // Vote Page
  voteHeading: {
    id: 'app.festival.voteHeading',
    defaultMessage: 'Flix Premiere Film Festival Awards',
  },
  voteDescription: {
    id: 'app.festival.voteDescription',
    defaultMessage: 'Have your say with the Flix Premiere Online Film Festival Audience Awards.',
  },
  voteInstruction: {
    id: 'app.festival.voteInstruction',
    defaultMessage: 'Tick the box for the film you would like to vote for.',
  },
  voteEmpty: {
    id: 'app.festival.voteEmpty',
    defaultMessage: 'There are no voting options available at the moment. Please try again later.',
  },
  voteSuccess: {
    id: 'app.festival.voteSuccess',
    defaultMessage: `Your vote has been submitted! View or edit your votes in My Festival.`,
  },
  votingStartsSoon: {
    id: 'app.festival.votingStartsSoon',
    defaultMessage: `Voting opens soon...`,
  },
  votingFinished: {
    id: 'app.festival.votingFinished',
    defaultMessage: `Voting is now closed. Winners announcement coming soon.`,
  },
  votingCountdownHeading: {
    id: 'app.festival.votingCountdownHeading',
    defaultMessage: `Voting Closes in`,
  },

  // Placeholder
  placeholderHeading: {
    id: 'app.festival.placeholderHeading',
    defaultMessage: 'Flix Premiere Online Film Festival',
  },
  placeholderDescription: {
    id: 'app.festival.placeholderDescription',
    defaultMessage:
      'Welcome to the official Flix Premiere Online Film Festival - a place where you can get exclusive access to top independent films plus extra features.',
  },
  placeholderNextFestival: {
    id: 'app.festival.placeholderNextFestival',
    defaultMessage: 'The next festival is coming soon...',
  },
  latestNews: {
    id: 'app.festival.latestNews',
    defaultMessage: 'Latest News',
  },

  // Misc
  watchListAddSuccess: {
    id: 'app.festival.watchListAddSuccess',
    defaultMessage: `Added to your watchlist`,
  },
  watchListRemoveSuccess: {
    id: 'app.festival.watchListRemoveSuccess',
    defaultMessage: `Removed from your watchlist`,
  },
})
