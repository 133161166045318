import { memo } from 'react'
import { Pressable, View } from 'react-native'
import styled from 'styled-components'
import { PremierePanelAd } from '../../ads/nativeAds/PremierePanelAd.js'
import { FilmPromoPanel } from '../../content/FilmPromoPanel/FilmPromoPanel'

const Touchable = styled(Pressable)`
  padding-left: ${(props) => props.theme.spacing.sm / 2}px;
  padding-right: ${(props) => props.theme.spacing.sm / 2}px;
  padding-bottom: ${(props) => props.theme.spacing.medium}px;
`

const PremieresContentBase = ({
  item,
  index,
  theme,
  width,
  onChosen,
  showCountDown = true,
  showPremiereDate,
}) => {
  return item.type === 'ad' ? (
    <Touchable as={View}>
      <PremierePanelAd
        zone={item.zone}
        placementXs={item.placementMobile}
        placementSm={item.placement}
        index={item.index}
        width={width - theme.spacing.sm}
      />
    </Touchable>
  ) : (
    <Touchable onPress={() => onChosen(item)}>
      <FilmPromoPanel
        film={item}
        width={width - theme.spacing.sm}
        showCountDown={showCountDown && !index}
        showPremiereDate={showPremiereDate}
      />
    </Touchable>
  )
}

export const PremieresContent = memo(PremieresContentBase)

PremieresContent.getNumColumns = () => 1
