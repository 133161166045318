import PropTypes from 'prop-types'
import { IconButton } from '../../../../elements/controls/IconButton/IconButton.js'
import messages from '../../messages.js'

export const TrailerButton = ({ onPress, width, ...props }) => (
  <IconButton
    {...props}
    width={width}
    name="play-clapboard"
    intlMsg={messages.playTrailer}
    onPress={onPress}
  />
)

TrailerButton.propTypes = {
  onPress: PropTypes.func,
}
