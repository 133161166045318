import { useRef } from 'react'
import styled, { css } from 'styled-components'
import Image from '../../../elements/images/Image/Image'
import { ImagePlaceholder } from '../../../elements/images/ImagePlaceholder/ImagePlaceholder'
import { defaultTransforms } from '../../../services/cloudinary/index.js'
import { PosterPremiumOfferTitle } from '../../content-lists/PosterLandscapeContent/PosterPremiumOfferTitle'
import { LiveEventBadge, LiveEventBadgeWrapper } from '../LiveEventDetails/LiveEventBadge'
import { zoomScale } from './FilmLandscapePoster.constants.js'

const calcHeight = (width) => width * 0.55
const calcZoomedDimension = (dimension) => dimension * zoomScale

// Setting scaledSize forces the full size image to the smaller carousel dimensions. It is done
// using SC so the scaled dimensions can go into a CSS style rather than inline in each item.
export const ScaledImage = styled(Image)`
  ${(props) =>
    props.scaledSize &&
    css`
      width: ${(props) => props.scaledSize.width}px;
      height: ${(props) => props.scaledSize.height}px;
    `};
`

export const FilmLandscapePoster = ({
  item,
  width: inputWidth,
  extraTransforms = [],
  zoomed,
  onMouseMove,
  premiumTitle,
  isTopStreaming,
  ...props
}) => {
  const itemContainer = useRef()

  if (inputWidth <= 0) {
    return null
  }

  // Default size within the carousel
  const width = Math.ceil(inputWidth)
  const height = Math.ceil(calcHeight(width))

  // Dimensions of the poster image when zoomed on hover
  const zoomedWidth = Math.ceil(width * zoomScale)
  const zoomedHeight = Math.ceil(height * zoomScale)

  return (
    <ImagePlaceholder
      width={zoomed ? zoomedWidth : width}
      height={zoomed ? zoomedHeight : height}
      ref={itemContainer}
      onMouseMove={
        onMouseMove &&
        ((ev) =>
          itemContainer.current.measure((fx, fy, width, height, x, y) => {
            onMouseMove(ev, item, { x, y, width, height, zoomedWidth, zoomedHeight })
          }))
      }
    >
      <ScaledImage
        transforms={[
          defaultTransforms,
          // Use the full width & height so the image doesn't have to be refetched when hovered
          ...logoOverlayTransformIfNeeded(item, zoomedWidth, zoomedHeight),
          ...extraTransforms,
        ]}
        {...props}
        alt={item.title}
        width={zoomedWidth}
        height={zoomedHeight}
        publicId={item.posterLandscapeImg}
        scaledSize={zoomed ? false : { width, height }}
      />
      {item.paymentModel && premiumTitle && <PosterPremiumOfferTitle item={item} zoomed={zoomed} />}

      {(item.type === 'live' || isTopStreaming) && (
        <LiveEventBadgeWrapper>
          <LiveEventBadge status={item.status ?? 'OnNow'} />
        </LiveEventBadgeWrapper>
      )}
    </ImagePlaceholder>
  )
}

// When there is no landscape banner the API provides the background and logo image
export const logoOverlayTransformIfNeeded = (film, width, height) => {
  if (!film.filmTitleImg) return []
  return [
    { gravity: 'east', width, height },
    {
      overlay: film.filmTitleImg.replace(/\//g, ':'), // escape / with : for overlays
      width: Math.round(width * 0.45),
      height: Math.round(height * 0.5),
      x: Math.round(width * 0.03),
      gravity: 'west',
      crop: 'pad',
    },
  ]
}

FilmLandscapePoster.calcHeight = calcHeight
FilmLandscapePoster.calcZoomedDimension = calcZoomedDimension
