import { memo } from 'react'
import styled from 'styled-components'
import { TouchableNoFeedback } from '@flix/common/elements/controls'
import { FestivalLineupPanel } from '../../festival/FestivalLineup/FestivalLineupPanel'

const Touchable = styled(TouchableNoFeedback)`
  padding-left: ${(props) => props.theme.spacing.sm / 2}px;
  padding-right: ${(props) => props.theme.spacing.sm / 2}px;
  padding-bottom: ${(props) => props.theme.spacing.medium}px;
`

export const FestivalLineupContent = memo(({ item, theme, width, onChosen, ...panelProps }) => (
  <Touchable onPress={() => onChosen(item)}>
    <FestivalLineupPanel {...panelProps} film={item} width={width - theme.spacing.sm} />
  </Touchable>
))

FestivalLineupContent.getNumColumns = () => 1
