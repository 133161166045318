import { forwardRef, useCallback, useImperativeHandle, useMemo, useRef } from 'react'
import { View } from 'react-native'
import SlickSlider from 'react-slick'
import { noop } from 'lodash'
import PropTypes from 'prop-types'
import 'slick-carousel/slick/slick.css'
import { useLayout } from '../../../hooks'
import { useKeyboard } from '../../../hooks/useKeyboard/useKeyboard'
import { useRTL } from '../../../style/RtlProvider'
import { useMediaSelect } from '../../../style/media'
import { useCarouselSavedIndex } from './CarouselContext'

// The same default as React and RN FlatList
const keyExtractorDefault = (item, index) => item?.key ?? item?.slug ?? index.toString()

export const BaseCarousel = forwardRef(
  (
    {
      items,
      renderItem,
      keyExtractor = keyExtractorDefault,
      onIndexChange = noop,
      Slide = View,
      initialSlide,
      scrollPositionSlug,
      responsive,
      style,
      keyboardNavEnabled = false,
      ...props
    },
    ref,
  ) => {
    // Props that can have responsive overrides
    const sliderSettings = {
      dots: false,
      arrows: false,
      infinite: true,
      speed: 500,
      touchThreshold: 20,
      ...props,
      ...useMediaSelect(responsive),
    }
    const { slidesToShow = 1 } = sliderSettings

    const { width = 0, onLayout } = useLayout()

    // ScrollView and Slide layout calculations based on the measured `width`
    const { slideWidth, slideStyle } = useMemo(() => {
      const slideWidth = width / slidesToShow
      return {
        slideWidth,
        slideStyle: { maxWidth: slideWidth, flexBasis: slideWidth },
      }
    }, [width, slidesToShow])

    // The slider collapses when there are fewer items than those configured to show
    if (items.length < slidesToShow) {
      sliderSettings.slidesToShow = items.length - 1
      sliderSettings.slidesToScroll = 1
    }

    // Improves the swipe UX on when scrolling one item at a time,
    // but it tends to snap to the wrong slide when scrolling more than one
    sliderSettings.swipeToSlide = sliderSettings.slidesToScroll === 1

    const { initialIndex, saveIndex } = useCarouselSavedIndex(scrollPositionSlug, initialSlide)

    // Invert the arguments of the current interval/position updates
    const beforeChange = useCallback(
      (oldIdx, newIdx) => {
        saveIndex(newIdx)
        onIndexChange(newIdx, oldIdx)
      },
      [onIndexChange, saveIndex],
    )

    const { isRTL } = useRTL()

    // Customise the imperative interface for changing the current position
    const sliderRef = useRef()

    const slickNext = () => sliderRef.current?.slickNext()
    const slickPrev = () => sliderRef.current?.slickPrev()

    useImperativeHandle(ref, () => ({
      slideNext: !isRTL ? slickNext : slickPrev,
      slidePrev: !isRTL ? slickPrev : slickNext,
      slideGoTo: (index, dontAnimate) => sliderRef.current.slickGoTo(index, dontAnimate),
    }))

    // keyboard navigation works out of the box in RTL
    useKeyboard({
      enabled: keyboardNavEnabled,
      right: slickNext,
      left: slickPrev,
    })

    if (items.length <= slidesToShow) {
      return (
        <View style={{ flexDirection: 'row' }} onLayout={onLayout}>
          {!!width &&
            items.map((item, index) => (
              <Slide key={keyExtractor(item, index)} style={slideStyle}>
                {renderItem(item, index, slideWidth)}
              </Slide>
            ))}
        </View>
      )
    }

    return (
      <View style={style} onLayout={onLayout}>
        {!!width && (
          <SlickSlider
            {...sliderSettings}
            ref={sliderRef}
            beforeChange={beforeChange}
            initialSlide={initialIndex}
          >
            {items.map((item, index) => (
              <Slide key={keyExtractor(item, index)} style={slideStyle}>
                {renderItem(item, index, slideWidth)}
              </Slide>
            ))}
          </SlickSlider>
        )}
      </View>
    )
  },
)

BaseCarousel.propTypes = {
  items: PropTypes.array.isRequired,
  renderItem: PropTypes.func.isRequired,
  keyExtractor: PropTypes.func,
  slidesToShow: PropTypes.number,
  slidesToScroll: PropTypes.number,
  autoplay: PropTypes.bool,
  autoplaySpeed: PropTypes.number,
  initialSlide: PropTypes.number,
  scrollPositionSlug: PropTypes.string,
  onIndexChange: PropTypes.func,
  responsive: PropTypes.shape({
    lg: PropTypes.object,
    md: PropTypes.object,
    sm: PropTypes.object,
    xs: PropTypes.object,
  }),
}
