import PropTypes from 'prop-types'
import { IconButton } from '../../../../../elements/controls/IconButton/IconButton.js'
import messages from '../../../../downloads/messages.js'

export const DownloadCompleteButton = ({ onPress }) => (
  <IconButton name="phone-tick" intlMsg={messages.downloaded} onPress={onPress} />
)

DownloadCompleteButton.propTypes = {
  onPress: PropTypes.func,
}
