import { TouchableWithoutFeedback, View } from 'react-native'
import styled from 'styled-components'
import { cursorPointer } from '../../../style/webStyles'

const ViewWithPointer = styled(View)`
  ${cursorPointer};
`

export const TouchableNoFeedback = ({ onPress, children, ...props }) => (
  <TouchableWithoutFeedback onPress={onPress}>
    <ViewWithPointer {...props}>{children}</ViewWithPointer>
  </TouchableWithoutFeedback>
)

export default TouchableNoFeedback
