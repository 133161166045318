import PropTypes from 'prop-types'
import Image from '../Image/Image'

const calcHeight = (width) => width * 1.5

const Poster = ({ width, disableAutoQuality, transforms, ...props }) => {
  return (
    <Image
      {...props}
      width={width}
      height={calcHeight(width)}
      transforms={
        Array.isArray(transforms)
          ? [posterTransforms(disableAutoQuality, transforms[0]), ...transforms.slice(1)]
          : posterTransforms(disableAutoQuality, transforms)
      }
    />
  )
}

const posterTransforms = (disableAutoQuality, transforms) => ({
  crop: 'scale',
  quality: disableAutoQuality ? 'auto:good' : 'auto',
  fetchFormat: disableAutoQuality ? null : 'auto',
  ...transforms,
})

Poster.propTypes = {
  width: PropTypes.number.isRequired,
  publicId: PropTypes.string.isRequired,
  disableAutoQuality: PropTypes.bool,
}

Poster.calcHeight = calcHeight

export default Poster
