import { useRef } from 'react'
import { View } from 'react-native'
import { useMediaSelect } from '../../../style/media.js'
import { BaseCarousel } from '../BaseCarousel/BaseCarousel'
import { ChevronArrowLeft, ChevronArrowRight } from './ChevronArrow'

export const FadingArrowCarousel = ({
  gradientOpacity,
  height,
  style,
  showPrev = true,
  showNext = true,
  ...props
}) => {
  const slidesToShow = useMediaSelect(props.responsive)?.slidesToShow ?? props.slidesToShow

  const slider = useRef()
  const slidePrev = () => slider.current.slidePrev()
  const slideNext = () => slider.current.slideNext()
  const scrollable = props.items.length > slidesToShow

  return (
    <View style={height ? { ...style, height } : style}>
      <BaseCarousel {...props} ref={slider} />
      {scrollable && showPrev && (
        <ChevronArrowLeft onPress={slidePrev} gradientOpacity={gradientOpacity} />
      )}
      {scrollable && showNext && (
        <ChevronArrowRight onPress={slideNext} gradientOpacity={gradientOpacity} />
      )}
    </View>
  )
}

FadingArrowCarousel.availableWidth = (width) => width
