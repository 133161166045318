"use strict";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { useQuery } from '@apollo/client';
var FILM_RECOMMENDED_LIST = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "FilmRecommendedQuery"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "filmId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      },
      "directives": []
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "stv"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      },
      "directives": []
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "limit"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      },
      "directives": []
    }],
    "directives": [],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "filmRecommendedList"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "filmId"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "filmId"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "stv"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "stv"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "limit"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "limit"
            }
          }
        }],
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "rest"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "type"
            },
            "value": {
              "kind": "StringValue",
              "value": "FilmGroupList",
              "block": false
            }
          }, {
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "endpoint"
            },
            "value": {
              "kind": "StringValue",
              "value": "v2",
              "block": false
            }
          }, {
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "path"
            },
            "value": {
              "kind": "StringValue",
              "value": "film/list/recommended?stv={args.stv}&limit={args.limit}&film_id={args.filmId}",
              "block": false
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "slug"
            },
            "arguments": [],
            "directives": []
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "title"
            },
            "arguments": [],
            "directives": []
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "listType"
            },
            "arguments": [],
            "directives": []
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "items"
            },
            "arguments": [],
            "directives": []
          }]
        }
      }]
    }
  }],
  "loc": {
    "start": 0,
    "end": 369,
    "source": {
      "body": "\n  query FilmRecommendedQuery($filmId: ID!, $stv: Int!, $limit: Int!) {\n    filmRecommendedList(filmId: $filmId, stv: $stv, limit: $limit)\n      @rest(\n        type: \"FilmGroupList\"\n        endpoint: \"v2\"\n        path: \"film/list/recommended?stv={args.stv}&limit={args.limit}&film_id={args.filmId}\"\n      ) {\n      slug\n      title\n      listType\n      items\n    }\n  }\n",
      "name": "GraphQL request",
      "locationOffset": {
        "line": 1,
        "column": 1
      }
    }
  }
};
export var useFilmRecommendedQuery = function useFilmRecommendedQuery(_ref) {
  var filmId = _ref.filmId,
      stv = _ref.stv,
      _ref$limit = _ref.limit,
      limit = _ref$limit === void 0 ? 8 : _ref$limit;
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : undefined;
  return useQuery(FILM_RECOMMENDED_LIST, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      filmId: filmId,
      stv: stv ? 1 : 0,
      limit: limit
    }
  }));
};