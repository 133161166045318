import PropTypes from 'prop-types'
import { IconButton } from '@flix/common/elements/controls/IconButton/IconButton.js'
import messages from '../../messages.js'

export const WatchListButton = ({ width, onPress, isOnWatchList, ...props }) => {
  return (
    <IconButton
      {...props}
      width={width}
      name={isOnWatchList ? 'ticket-checked' : 'ticket-add'}
      intlMsg={messages.watchList}
      onPress={onPress}
    />
  )
}

WatchListButton.propTypes = {
  film: PropTypes.object,
}
