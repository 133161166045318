"use strict";

import { useCallback } from 'react';
import { useFlixApiContext } from '../FlixApiContext';
export var useLogStripeError = function useLogStripeError(bugsnagNotify) {
  var _useFlixApiContext = useFlixApiContext(),
      flixApi = _useFlixApiContext.flixApi;

  var logStripeError = useCallback(function (error) {
    return flixApi('post', 'subscription/log-error/stripe', {
      error: error
    }).catch(function (logError) {
      bugsnagNotify(logError, function (event) {
        event.addMetadata('error', error);
      });
    });
  }, [flixApi, bugsnagNotify]);
  return {
    logStripeError: logStripeError
  };
};