import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { Text } from '../../../elements/typography'

const EmptyListMessage = styled(Text)`
  font-size: ${(props) => props.theme.fontSizes.small}px;
  margin-top: ${(props) => props.theme.spacing.lg * 2.5}px;
  text-align: center;
`

export const EmptyList = ({ message, values, style }) => (
  <EmptyListMessage style={style}>
    <FormattedMessage {...message} values={values} />
  </EmptyListMessage>
)
