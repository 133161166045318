"use strict";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { useQuery } from '@apollo/client';
import keyBy from 'lodash/keyBy';
export var PAYMENT_MODELS = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "PaymentModelsQuery"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "provider"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      },
      "directives": []
    }],
    "directives": [],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "paymentModels"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "provider"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "provider"
            }
          }
        }],
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "rest"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "type"
            },
            "value": {
              "kind": "StringValue",
              "value": "PaymentModel",
              "block": false
            }
          }, {
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "endpoint"
            },
            "value": {
              "kind": "StringValue",
              "value": "v2",
              "block": false
            }
          }, {
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "path"
            },
            "value": {
              "kind": "StringValue",
              "value": "info/payment-model/{args.provider}",
              "block": false
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "slug"
            },
            "arguments": [],
            "directives": []
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "title"
            },
            "arguments": [],
            "directives": []
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "type"
            },
            "arguments": [],
            "directives": []
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "items"
            },
            "arguments": [],
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "type"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "name"
                },
                "value": {
                  "kind": "StringValue",
                  "value": "PaymentModelTier",
                  "block": false
                }
              }]
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "slug"
                },
                "arguments": [],
                "directives": []
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "sku"
                },
                "arguments": [],
                "directives": []
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "title"
                },
                "arguments": [],
                "directives": []
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "price"
                },
                "arguments": [],
                "directives": []
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "currency"
                },
                "arguments": [],
                "directives": []
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "currencySymbol"
                },
                "arguments": [],
                "directives": []
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "paymentNote"
                },
                "arguments": [],
                "directives": []
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "expirationHours"
                },
                "arguments": [],
                "directives": []
              }]
            }
          }]
        }
      }]
    }
  }],
  "loc": {
    "start": 0,
    "end": 440,
    "source": {
      "body": "\n  query PaymentModelsQuery($provider: String!) {\n    paymentModels(provider: $provider)\n      @rest(type: \"PaymentModel\", endpoint: \"v2\", path: \"info/payment-model/{args.provider}\") {\n      slug\n      title\n      type\n      items @type(name: \"PaymentModelTier\") {\n        slug\n        sku\n        title\n        price\n        currency\n        currencySymbol\n        paymentNote\n        # TVOD only\n        expirationHours\n      }\n    }\n  }\n",
      "name": "GraphQL request",
      "locationOffset": {
        "line": 1,
        "column": 1
      }
    }
  }
};
export var usePaymentModelsQuery = function usePaymentModelsQuery() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      provider = _ref.provider;

  var options = arguments.length > 1 ? arguments[1] : undefined;
  // const { paymentProvider } = useFlixApiContext()
  var result = useQuery(PAYMENT_MODELS, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      provider: ''
      /*provider || paymentProvider*/

    }
  }));
  return _objectSpread(_objectSpread({}, result), {}, {
    data: result.data && keyBy(result.data.paymentModels, 'slug')
  });
};