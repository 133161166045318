import { View } from 'react-native'
import styled, { useTheme } from 'styled-components'
import { FeatureButton, TrailerButton, WatchListButton } from '../../../../content/WatchButtons'

const ButtonMarginWrapper = styled(View)`
  margin-right: ${(props) => props.theme.spacing.sm}px;
`

export const LineupPanelButtonsMobile = ({
  playFeature,
  playTrailer,
  addToWatchList,
  removeFromWatchList,
  isPlayable,
  isOnWatchList,
  isFullySubscribed,
}) => {
  const theme = useTheme()
  const buttonIconSize = theme.spacing.md * 1.5
  return (
    <>
      <ButtonMarginWrapper>
        {isPlayable ? (
          <FeatureButton
            textDefinesWidth
            userMayPlay={isPlayable}
            size={buttonIconSize}
            onPress={playFeature}
          />
        ) : (
          <TrailerButton textDefinesWidth size={buttonIconSize} onPress={playTrailer} />
        )}
      </ButtonMarginWrapper>
      {isFullySubscribed && (
        <WatchListButton
          textDefinesWidth
          size={buttonIconSize}
          onPress={isOnWatchList ? () => removeFromWatchList() : addToWatchList()}
        />
      )}
    </>
  )
}
