import { differenceInDays, differenceInWeeks, parseJSON } from 'date-fns'
import { format, utcToZonedTime } from 'date-fns-tz'
import { formatShortTZName } from '../../utils/dates.js'

export function formatPremiereDateString(premiereFrom, timeZone, today = new Date()) {
  if (!premiereFrom) {
    return ''
  }

  const date = parseJSON(premiereFrom)

  let dayAndDate
  if (Math.abs(differenceInDays(date, today)) < 1) {
    dayAndDate = "'tonight'"
  } else if (differenceInWeeks(date, today) < 1) {
    dayAndDate = "'this' EEEE" // e.g. Saturday
  } else {
    dayAndDate = 'EEEE, d MMM' // e.g. Saturday, 6 Feb
  }

  const tzString = timeZone.startsWith('America') ? ` ${formatShortTZName(timeZone, date)}` : '' // e.g. EST

  const zonedDate = utcToZonedTime(date, timeZone)
  const template = `'Showing' ${dayAndDate} 'from' haaa'${tzString}.` // e.g. 7pm EST

  return format(zonedDate, template)
}
