import { useMediaIs } from '../../../../style/media'
import { LeaderboardAd } from '../../../ads/bannerAds/LeaderboardAd.js'
import { MobileLeaderboard } from '../../../ads/bannerAds/MobileLeaderboard.js'
import { useBannerAdDecision } from '../../../ads/useBannerAdDecision.js'

export const FilmDetailsLeaderboardAd = ({ zone, placementXs, placementSm, filmSlug, genres }) => {
  const sm = useMediaIs('sm')

  const placement = sm ? placementSm : placementXs

  const bannerDetails = useBannerAdDecision(zone, placement, { filmSlug, genres })

  return sm ? (
    <LeaderboardAd data={bannerDetails} mt="md" />
  ) : (
    <MobileLeaderboard data={bannerDetails} mt="md" />
  )
}
