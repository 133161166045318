import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Alert, View } from 'react-native'
import styled from 'styled-components'
import { ActionSheet, useButtonHandlers } from '../../../../elements/controls/index.js'
import { useNetworkInfo } from '../../../../hooks/index.js'
import { media } from '../../../../style/media.js'
import { useDownloadErrorAlert } from '../../../downloads/DownloadError/DownloadError.js'
import { useFilmDownloads } from '../../../downloads/FilmDownloadContext.js'
import { downloadHasExpired } from '../../../downloads/downloadHasExpired.js'
import downloadMsgs from '../../../downloads/messages.js'
import { DownloadCompleteButton } from './DownloadCompleteButton/DownloadCompleteButton.js'
import { DownloadErrorButton } from './DownloadErrorButton/DownloadErrorButton.js'
import { DownloadExpiredButton } from './DownloadExpiredButton/DownloadExpiredButton.js'
import { DownloadProgressButton } from './DownloadProgressButton/DownloadProgressButton.js'
import { DownloadQueuedButton } from './DownloadQueuedButton/DownloadQueuedButton.js'
import { DownloadStartButton } from './DownloadStartButton/DownloadStartButton.js'

const DownloadButtonWrapper = styled(View)`
  flex: 1 0 90px;
  flex-direction: row;
  justify-content: center;

  ${media.sm`
    flex-basis: 60px;
  `}
`

export const DownloadButton = ({ film }) => {
  const intl = useIntl()
  const { handlers } = useButtonHandlers()
  const { downloads, downloadHandlers } = useFilmDownloads()
  const { type: connectionType } = useNetworkInfo()
  const { download, error: downloadError } = downloads?.current[film.slug] ?? {}

  const actions = useMemo(() => {
    const { cancelDownload, removeDownload, pauseAllDownloads, resumeAllDownloads } =
      downloadHandlers
    return {
      pauseAllDownloads: {
        text: intl.formatMessage(downloadMsgs.pauseAllDownloads),
        onPress: () => pauseAllDownloads(film),
      },
      resumeAllDownloads: {
        text: intl.formatMessage(downloadMsgs.resumeAllDownloads),
        onPress: () => resumeAllDownloads(film),
      },
      removeDownload: {
        text: intl.formatMessage(downloadMsgs.removeDownload),
        onPress: () => removeDownload(film),
      },
      cancelDownload: {
        text: intl.formatMessage(downloadMsgs.cancelDownload),
        onPress: () => cancelDownload(film),
      },
      playFeature: {
        text: intl.formatMessage(downloadMsgs.playFilm),
        onPress: () => handlers.playDownload(film),
      },
      viewAllDownloads: {
        text: intl.formatMessage(downloadMsgs.viewAllDownloads),
        onPress: () => handlers.viewDownloads(),
      },
    }
  }, [handlers, downloadHandlers, film, intl])

  const openErrorAlert = useDownloadErrorAlert(downloadError, actions.removeDownload.onPress)

  if (!film.download?.available) {
    return null
  }

  if (!download) {
    const queueDownload = () => downloadHandlers.queueDownload(film)

    return (
      <DownloadButtonWrapper>
        <DownloadStartButton
          onPress={
            connectionType !== 'cellular'
              ? queueDownload
              : () =>
                  Alert.alert(
                    intl.formatMessage(downloadMsgs.noWifiAlertTitle),
                    intl.formatMessage(downloadMsgs.noWifiAlertBody),
                    [
                      {
                        text: intl.formatMessage(downloadMsgs.noWifiAlertConfirm),
                        onPress: queueDownload,
                      },
                      { text: intl.formatMessage(downloadMsgs.noWifiAlertReject) },
                    ],
                  )
          }
        />
      </DownloadButtonWrapper>
    )
  }

  if (downloadError) {
    return (
      <DownloadButtonWrapper>
        <DownloadErrorButton onPress={openErrorAlert} />
      </DownloadButtonWrapper>
    )
  }

  if (download.status === 'queued') {
    return (
      <DownloadButtonWrapper>
        <ActionSheet
          options={[actions.pauseAllDownloads, actions.cancelDownload, actions.viewAllDownloads]}
          destructiveButtonIndex={1}
        >
          {(openActionSheet) => (
            <DownloadQueuedButton onPress={openActionSheet} progress={download.progress} />
          )}
        </ActionSheet>
      </DownloadButtonWrapper>
    )
  }

  if (downloadHasExpired(download)) {
    return (
      <DownloadButtonWrapper>
        <ActionSheet
          options={[actions.viewAllDownloads, actions.removeDownload]}
          destructiveButtonIndex={1}
        >
          {(openActionSheet) => <DownloadExpiredButton onPress={openActionSheet} />}
        </ActionSheet>
      </DownloadButtonWrapper>
    )
  }

  if (download.status === 'complete') {
    return (
      <DownloadButtonWrapper>
        <ActionSheet
          options={[actions.playFeature, actions.removeDownload, actions.viewAllDownloads]}
          destructiveButtonIndex={1}
        >
          {(openActionSheet) => <DownloadCompleteButton onPress={openActionSheet} />}
        </ActionSheet>
      </DownloadButtonWrapper>
    )
  }

  const isPaused = download.status === 'paused'

  return (
    <DownloadButtonWrapper>
      <ActionSheet
        options={[
          isPaused ? actions.resumeAllDownloads : actions.pauseAllDownloads,
          actions.cancelDownload,
          actions.viewAllDownloads,
        ]}
        destructiveButtonIndex={1}
      >
        {(openActionSheet) => (
          <DownloadProgressButton
            isPaused={isPaused}
            progress={download.progress}
            onPress={openActionSheet}
          />
        )}
      </ActionSheet>
    </DownloadButtonWrapper>
  )
}
