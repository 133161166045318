import { useMemo } from 'react'
import { usePaymentModelsQuery } from '@mwxltd/flix-api-client'
import { useUserRentedFilmSlugs } from '@mwxltd/flix-api-client/src/user/UserFilms/useUserFilmSlugsQuery.js'
import { PaymentModelContext } from './usePaymentModel.js'

// Isolated from usePaymentModel to avoid importing the flix-api-client into apps
// that don't need it. This should not be imported in the common library.
export const PaymentModelProvider = ({ children }) => {
  const { data } = usePaymentModelsQuery()

  const rentedFilms = useUserRentedFilmSlugs()

  const value =
    useMemo(
      () => ({
        rentedFilms,
        paymentModelConfig: data,
      }),
      [data, rentedFilms],
    ) ?? {}

  return <PaymentModelContext.Provider value={value}>{children}</PaymentModelContext.Provider>
}
