import PropTypes from 'prop-types'
import Poster from '../../../elements/images/Poster/Poster'

export const ActorPoster = ({ item, width, ...props }) =>
  !width ? null : (
    <Poster
      alt={item.name || item.title}
      width={width}
      {...props}
      publicId={item.posterImg}
      disableAutoQuality
    />
  )

ActorPoster.propTypes = {
  item: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
}

export default ActorPoster
