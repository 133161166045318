import { TouchableOpacity } from 'react-native'
import styled from 'styled-components'
import { useUserProfileQuery } from '@mwxltd/flix-api-client'
import { formatPrice } from '@flix/common/domain/subscriptions/helpers'
import { Icon } from '@flix/common/elements/images/Icon'
import {
  Backdrop,
  Background,
  CloseButton,
  Content,
  Modal,
} from '@flix/common/elements/layout/Modal'
import { ActivityIndicator } from '@flix/common/elements/status'
import { H2, Paragraph } from '@flix/common/elements/typography'
import { usePaymentModel } from '@flix/common/hooks/usePaymentModel/usePaymentModel'
import { TVODCardPaymentForm } from './TVODCardPaymentForm'

const SubTitle = styled(Paragraph)`
  text-align: center;

  font-size: ${(props) => props.theme.fontSizes.large}px;
  margin-bottom: ${(props) => props.theme.spacing.larger}px;
`

export const TVODPurchaseModal = ({ visible, onRequestClose, film }) => {
  const { data: user, error, loading, refetch } = useUserProfileQuery({ skip: !visible })

  const { paymentModelConfig } = usePaymentModel()

  if (!visible) return null

  const userCards = user?.userProfile?.cards

  const tvodTier = paymentModelConfig?.tvod.items.find(
    (tier) => tier.slug === film?.paymentModel?.tvod[0],
  )

  return (
    <Modal visible onRequestClose={onRequestClose} transparent>
      <Backdrop>
        <Background>
          <Content>
            <H2>Complete your Purchase</H2>
            <SubTitle>
              Once you complete your payment of {formatPrice(tvodTier)} you will have access to{' '}
              {film?.title} for {tvodTier?.expirationHours} hours.
            </SubTitle>

            {/* todo - display errors properly */}
            {error && <Paragraph>{error.message}</Paragraph>}

            {tvodTier &&
              (loading ? (
                <ActivityIndicator />
              ) : (
                <TVODCardPaymentForm
                  film={film}
                  tvodTier={tvodTier}
                  userCards={userCards}
                  onPurchaseSuccess={onRequestClose}
                  loadCards={refetch}
                />
              ))}
          </Content>

          <CloseButton scale={0.75}>
            <TouchableOpacity onPress={onRequestClose}>
              <Icon name="close" scale={0.75} />
            </TouchableOpacity>
          </CloseButton>
        </Background>
      </Backdrop>
    </Modal>
  )
}
