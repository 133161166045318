import { AdPlacement } from '../AdPlacement.js'
import { NoAdInView } from '../NoAdInView.js'
import { BannerAdPlaceholder } from './BannerAdPlaceholder.js'

const width = 728
const height = 90

export const LeaderboardAd = ({ data, mb, mt }) => {
  const { bannerData, loading } = data

  if (loading) {
    return <BannerAdPlaceholder mb={mb} mt={mt} width={width} height={height} />
  }

  if (bannerData?.message === 'no ads') {
    return <NoAdInView data={bannerData} />
  }

  return <AdPlacement mb={mb} mt={mt} data={bannerData} width={width} height={height} />
}
