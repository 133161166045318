import { useMemo, useRef } from 'react'
import { View } from 'react-native'
import styled, { useTheme } from 'styled-components'
import { CardElement } from '@stripe/react-stripe-js'
import PropTypes from 'prop-types'
import {
  ErrorMessage,
  StyledInput as StyledInputBase,
  Container as TextInputContainer,
  styledInputStyles,
} from '@flix/common/elements/input/TextInput/TextInput'

// The input comes out smaller than the TextInput, but adjusting the padding fixes that
const StyledInput = styled(StyledInputBase)`
  padding: ${(props) => props.theme.spacing.xs * 1.5 + 2}px
    ${(props) => props.theme.spacing.xs * 1.5}px;
`

export const StripeCardInput = ({ input, onValidated, hidePostalCode = true, onReady }) => {
  const theme = useTheme()
  const lastError = useRef('')

  const handleChange = useMemo(() => {
    const setError = (message) => {
      if (lastError.current !== message) {
        lastError.current = message
        if (onValidated) {
          onValidated({ [input.name]: message })
        }
      }
    }
    return (ev) => (ev.error ? setError(ev.error.message) : setError(''))
  }, [lastError, input.name, onValidated])

  return (
    <TextInputContainer>
      <StyledInput as={View}>
        <CardElement
          {...input}
          onReady={onReady}
          onChange={handleChange}
          options={{
            hidePostalCode,
            style: {
              base: styledInputStyles(theme),
            },
          }}
        />
      </StyledInput>
      <ErrorMessage name={input.name} />
    </TextInputContainer>
  )
}

StripeCardInput.propTypes = {
  input: PropTypes.object.isRequired,
  onValidated: PropTypes.func.isRequired,
  hidePostalCode: PropTypes.bool,
}
