import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import {
  IconButtonContainer,
  IconLabel,
} from '../../../../../elements/controls/IconButton/IconButton.js'
import { CircularProgressIcon } from '../../../../../elements/status/CircularProgress/CircularProgressIcon.js'
import messages from '../../../../downloads/messages.js'

export const DownloadErrorButton = ({ onPress }) => (
  <IconButtonContainer onPress={onPress}>
    <CircularProgressIcon centerIcon="exclamation" color="warning" />
    <IconLabel>
      <FormattedMessage numberOfLines={1} {...messages.error} />
    </IconLabel>
  </IconButtonContainer>
)

DownloadErrorButton.propTypes = {
  onPress: PropTypes.func,
}
