import { isAfter, isFuture, isPast, isToday, parseJSON } from 'date-fns'
import { filter, pipe, sortBy } from 'lodash/fp'
import head from 'lodash/head'

export const getFestivalFilmStatus = (film) => {
  const start = parseJSON(film?.festivalStartDate)
  const end = parseJSON(film?.festivalEndDate)

  return {
    isFestivalFilm: !!film?.festivalStartDate,
    isFinished: isPast(end),
    isFuture: isFuture(start),
    isLaterToday: isFuture(start) && isToday(start),
    isLive: isPast(start) && isFuture(end),
  }
}

export const getNextFilmFromLineup = (films, currentFilm) => {
  const candidates = pipe(endsInFuture, exclude(currentFilm), sortByStartDate)(films)
  const startAfterCurrent = candidates.filter((film) =>
    isAfter(parseJSON(film.festivalStartDate), parseJSON(currentFilm?.festivalStartDate)),
  )

  return head(startAfterCurrent) || head(candidates)
}
const endsInFuture = filter((film) => isFuture(parseJSON(film.festivalEndDate)))
const exclude = (currentFilm) =>
  filter(
    (f) =>
      areDifferentOrMissing(f.id, currentFilm?.id) &&
      areDifferentOrMissing(f.slug, currentFilm?.slug),
  )
const areDifferentOrMissing = (a, b) => !a || !b || a !== b

const sortByStartDate = sortBy((film) => film.festivalStartDate)

export const getFestivalStatus = (festival) => {
  const votingStart = parseJSON(festival?.votingStartDate)
  const votingEnd = parseJSON(festival?.votingEndDate)
  return {
    awardsAnnounced: isPast(parseJSON(festival?.awardsAnnouncementDate)),
    votingStartsInFuture: isFuture(votingStart),
    votingFinished: isPast(votingEnd),
    votingLive: isPast(votingStart) && isFuture(votingEnd),
  }
}
