import { useMemo } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { useSubscriptionPlansFetch } from '@mwxltd/flix-api-client'

export const useStripeClient = () => {
  const getSubscriptionPlans = useSubscriptionPlansFetch({ provider: 'stripe' })
  return useMemo(
    () => getSubscriptionPlans().then(({ data: { stripeApiKey } }) => loadStripe(stripeApiKey)),
    [getSubscriptionPlans],
  )
}
