import { View } from 'react-native'
import styled from 'styled-components'
import { useButtonHandlers } from '../../../elements/controls/index.js'
import BackgroundImage from '../../../elements/images/BackgroundImage/BackgroundImage.js'
import Poster from '../../../elements/images/Poster/Poster.js'
import { H3, Text } from '../../../elements/typography/index.js'
import { useLayout } from '../../../hooks/index.js'
import { media, useMediaBreakpoints } from '../../../style/media.js'
import StarRating from '../StarRating/StarRating.js'
import { formatPremiereDateString } from '../formatPremiereDateString.js'
import { PromoCountdown } from './PromoCountdown/PromoCountdown.js'
import { PromoFilmButtons } from './PromoFilmButtons/PromoFilmButtons.js'

export const PromoPanelContainer = styled(View)`
  padding-bottom: ${(props) => props.theme.spacing.smedium}px;
  border-bottom-color: ${(props) => props.theme.colors.whiteFaded};
  border-bottom-width: 1px;
  margin-bottom: ${(props) => props.theme.spacing.smedium}px;
  ${media.sm`
    border-bottom-width: 0;
    margin-bottom: 0;
  `}
`

const Background = styled(BackgroundImage)`
  margin-bottom: ${(props) => props.theme.spacing.medium}px;
  ${media.sm`
    margin-bottom: 0;
    background-color: ${(props) => props.theme.colors.grayDarker};
    padding: ${(props) => props.theme.spacing.medium}px;
  `}
  ${media.md`
    padding: ${(props) => props.theme.spacing.large}px;
  `}
`

const Content = styled(View)`
  flex: 1;
  flex-direction: row;
  align-items: center;
`

const RightContent = styled(View)`
  flex: 1;
  height: 100%;
  padding: 0 ${(props) => props.theme.spacing.small}px;
  ${media.sm`
    padding: 0 ${(props) => props.theme.spacing.md}px;
  `}
`

const TitleAndRating = styled(View)`
  ${media.sm`
    margin-bottom: ${(props) => props.theme.spacing.small}px;
    flex-direction: row;
  `}
`

const FilmTitle = styled(H3)`
  margin-right: ${(props) => props.theme.spacing.small}px;
  line-height: ${(props) => props.theme.fontSizes.large * 1.2}px;
  font-size: ${(props) => props.theme.fontSizes.large}px;
  margin-bottom: 0;
  ${media.sm`
    line-height: ${(props) => props.theme.fontSizes.xlarge * 1.2}px;
    font-size: ${(props) => props.theme.fontSizes.xlarge}px;
  `};
  ${media.md`
    line-height: ${(props) => props.theme.fontSizes.xxlarge * 1.2}px;
    font-size: ${(props) => props.theme.fontSizes.xxlarge}px;
  `};
`

const Rating = styled(StarRating)`
  ${media.sm`
    margin-top: ${(props) => props.theme.spacing.xsmall}px;
  `};
`

const PremiereDateString = styled(Text)`
  margin-bottom: ${(props) => props.theme.spacing.xxsmall}px;
  font-family: ${(props) => props.theme.fontFamilies.baseBold};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${(props) => props.theme.fontSizes.small}px;
  ${media.sm`
    font-size: ${(props) => props.theme.fontSizes.large}px;
    margin-bottom: ${(props) => props.theme.spacing.smedium}px;
  `}
`

const ShortDescription = styled(Text)`
  color: ${(props) => props.theme.colors.grayLighter};
  font-size: ${(props) => props.theme.fontSizes.xsmall}px;
  margin-bottom: auto;

  ${media.sm`
    margin-bottom: ${(props) => props.theme.spacing.medium}px;
    font-size: ${(props) => props.theme.fontSizes.small}px;
    width: 56%;
  `}

  ${media.md`
    margin-bottom: ${(props) => props.theme.spacing.xlarge}px;
    font-size: ${(props) => props.theme.fontSizes.medium}px;
 `}

  ${media.lg`
    margin-bottom: ${(props) => props.theme.spacing.xlarge}px;
    font-size: ${(props) => props.theme.fontSizes.large}px;
  `}
`

const backgroundGradient = { x: 0.8, strength: 30 }

export const FilmPromoPanel = ({
  film,
  src,
  width,
  showCountDown = true,
  showPremiereDate = true,
  ...props
}) => {
  const {
    authStatus: { timeZone },
    reminders,
  } = useButtonHandlers()
  const { onLayout, height: contentHeight } = useLayout()
  const { sm, md, lg } = useMediaBreakpoints()

  return (
    <PromoPanelContainer {...props}>
      <Background
        bgImgId={!sm ? null : film.promoPanelBg}
        bgImgSource={src}
        width={width}
        height={Math.round(width * (lg ? 0.37 : sm ? 0.4 : 0.5))}
        gradient={backgroundGradient}
      >
        <Content onLayout={onLayout}>
          {!!contentHeight && (
            <>
              {film.posterImg && <Poster publicId={film.posterImg} width={contentHeight / 1.5} />}

              <RightContent>
                <TitleAndRating>
                  <FilmTitle numberOfLines={1}>{film.title}</FilmTitle>
                  <Rating rating={film.rating} scale={md ? 0.8 : 0.7} />
                </TitleAndRating>
                {showPremiereDate && Boolean(film.premiereFrom && timeZone) && (
                  <PremiereDateString>
                    {formatPremiereDateString(film.premiereFrom, timeZone)}
                  </PremiereDateString>
                )}
                <ShortDescription numberOfLines={4}>{film.shortDescription}</ShortDescription>
                {showCountDown && (
                  <PromoCountdown premiereFrom={film.premiereFrom} premiereTo={film.premiereTo} />
                )}

                {sm && <PromoFilmButtons film={film} reminders={reminders} />}
              </RightContent>
            </>
          )}
        </Content>
      </Background>
      {!sm && <PromoFilmButtons film={film} reminders={reminders} />}
    </PromoPanelContainer>
  )
}
