import { useTheme } from 'styled-components'
import { calcIconSize } from '../../images/Icon'
import { PaddedSVG } from '../../images/SVG/PaddedSVG'
import { CircularProgress } from './CircularProgress'

export const CircularProgressIcon = ({ size, scale, color, ...props }) => {
  const theme = useTheme()
  const finalSize = size || calcIconSize(theme, scale)

  return (
    <PaddedSVG padding={0.1} size={finalSize}>
      <CircularProgress size={finalSize * 0.9} color={color} {...props} />
    </PaddedSVG>
  )
}
