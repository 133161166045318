import { Platform, StyleSheet, TouchableOpacity, View } from 'react-native'
import styled from 'styled-components'
import { BackgroundImage } from '@flix/common/elements/images/BackgroundImage/BackgroundImage'
import { Icon } from '@flix/common/elements/images/Icon'
import { LinearGradient } from '@flix/common/elements/images/LinearGradient'
import { useMediaSelect } from '@flix/common/style/media'

const isWindows = Platform.OS === 'windows'

const Centered = styled(View)`
  justify-content: center;
  align-items: center;
`

const imageProps = {
  xs: { aspectRatio: 1.78 }, // 16:9
  sm: { aspectRatio: 3.82 },
  lg: { aspectRatio: 4.3 },
}

export const useOverviewBannerAspectRatio = () => useMediaSelect(imageProps).aspectRatio

export const FilmOverviewBanner = ({ width, source, publicId, children }) => {
  const aspectRatio = useOverviewBannerAspectRatio()
  return (
    <Centered
      as={BackgroundImage}
      width={width}
      aspectRatio={aspectRatio}
      bgImgId={publicId}
      bgImgSource={source}
      transforms={{ gravity: 'north' }}
      // This is a work-around for unsupported linear-gradient on windows
      gradient={isWindows ? { y: -0.3 } : undefined}
    >
      <LinearGradient
        colors={['rgba(0,0,0,0)', 'rgba(0,0,0,0)', 'rgba(18, 18, 18, 1)']}
        locations={[0, 0.7, 1]}
        start={{ x: 0, y: 0 }}
        end={{ x: 0, y: 1 }}
        style={StyleSheet.absoluteFill}
      />
      {children}
    </Centered>
  )
}

const IconBackground = styled(View)`
  width: ${(props) => props.theme.spacing.xlarge}px;
  height: ${(props) => props.theme.spacing.xlarge}px;
  background-color: #00000099;
  border-radius: 200px;
`

// The icon itself is not quite centered within its view box
const PlayIcon = styled(Icon).attrs({ name: 'play' })`
  margin-top: -1px;
  margin-left: 5px;
`

export const FilmOverviewBannerWithMobilePlay = ({ mobileLayout, onPlayPress, ...props }) => (
  <FilmOverviewBanner {...props}>
    {mobileLayout && (
      <TouchableOpacity onPress={onPlayPress}>
        <Centered style={StyleSheet.absoluteFill}>
          <IconBackground />
        </Centered>
        <PlayIcon />
      </TouchableOpacity>
    )}
  </FilmOverviewBanner>
)
