import { useState } from 'react'
import { Form } from 'react-final-form'
import { FormattedMessage } from 'react-intl'
import { View } from 'react-native'
import styled from 'styled-components'
import { Elements } from '@stripe/react-stripe-js'
import setFieldData from 'final-form-set-field-data'
import messages from '@flix/common/domain/membership/messages'
import { Button, ButtonRow } from '@flix/common/elements/controls'
import formMsgs from '@flix/common/elements/forms/messages'
import { ActivityIndicator } from '@flix/common/elements/status'
import { Text } from '@flix/common/elements/typography'
import { useSnackbar } from '@flix/common/hooks/useSnackbar/useSnackbar'
import { useMediaIs } from '@flix/common/style/media'
import { stripeElementFonts } from '@flix/common/style/theme'
import { useStripeClient } from '../../../containers/stripe/useStripeClient'
import { useStripeTVODPaymentSubmission } from '../../../containers/stripe/useStripeTVODPaymentSubmission'
import { TVODNewCardPayment } from './TVODNewCardPayment'
import { TVODSavedCardPayment } from './TVODSavedCardPayment'

const WaitHere = styled(View)`
  flex-direction: row;
  margin-bottom: ${(props) => props.theme.spacing.medium}px;
`

const Spinner = styled(ActivityIndicator)`
  margin-right: ${(props) => props.theme.spacing.medium}px;
`

const WaitText = styled(Text)`
  font-size: ${(props) => props.theme.fontSizes.small}px;
`

const PleaseWait = () => (
  <WaitHere>
    <Spinner />
    <WaitText>
      <FormattedMessage {...messages.doNotRefresh} />
    </WaitText>
  </WaitHere>
)

const TVODCardPaymentForm = ({ film, tvodTier, userCards, onPurchaseSuccess, loadCards }) => {
  const [newCardMethod, setNewCardMethod] = useState(null)
  const { snackbarMsg, snackbarError } = useSnackbar()

  const sm = useMediaIs('sm')

  const { handleCardSubmit } = useStripeTVODPaymentSubmission({
    film,
    tvodTier,
    onSuccess: () => {
      if (newCardMethod) {
        loadCards()
      }
      snackbarMsg(`You have successfully purchased ${film.title}`)
      onPurchaseSuccess()
    },
    onError: () =>
      snackbarError(
        `There was a problem purchasing ${film.title}. Please try again and contact support if the problem persists.`,
      ),
  })

  return (
    <Form onSubmit={handleCardSubmit} mutators={{ setFieldData }}>
      {({ handleSubmit, submitting }) => (
        <form
          onSubmit={handleSubmit}
          style={{ display: 'flex', flexDirection: 'column', width: '100%', maxWidth: 320 }}
        >
          {!newCardMethod && userCards ? (
            <TVODSavedCardPayment
              fieldName="savedCardId"
              userCards={userCards}
              setNewCardMethod={setNewCardMethod}
            />
          ) : (
            <TVODNewCardPayment />
          )}

          {submitting && <PleaseWait />}

          {!newCardMethod ? (
            <Button
              variant="primary"
              disabled={submitting}
              onPress={handleSubmit}
              title={submitting ? undefined : 'Pay now'}
              intlMessage={submitting ? formMsgs.submitting : undefined}
              style={sm ? { margin: 'auto' } : null}
            />
          ) : (
            <ButtonRow style={{ alignItems: 'baseline' }}>
              <Button
                onPress={() => setNewCardMethod(null)}
                disabled={submitting}
                title="Cancel"
                link
                underline={false}
                mb="none"
              />
              <Button
                disabled={submitting}
                onPress={handleSubmit}
                title={submitting ? undefined : 'Add new card'}
                intlMessage={submitting ? formMsgs.submitting : undefined}
              />
            </ButtonRow>
          )}

          {/* A form requires a type="submit" button to submit on Enter press */}
          <button type="submit" style={{ display: 'none' }} />
        </form>
      )}
    </Form>
  )
}

// The component rendering the Stripe input must be wrapped in Elements
const withStripeElements = (Component) => (props) => {
  const stripe = useStripeClient()
  return (
    <Elements stripe={stripe} options={{ fonts: stripeElementFonts }}>
      <Component {...props} />
    </Elements>
  )
}

const TVODCardPaymentFormWithElements = withStripeElements(TVODCardPaymentForm)

export { TVODCardPaymentFormWithElements as TVODCardPaymentForm }
