import { Platform } from 'react-native'

export const findMonthlyPlan = (plans = []) => plans.find((item) => item.type.startsWith('MONTHLY'))

// Only Stripe and PayPal subscriptions can be managed via the Flix API, and
// only apps able to initiate Stripe and PayPal subscriptions can manage them.
// The iOS app is an exception, which links to the iTunes Store.
// PayPal can't be updated as it requires additional step with user's approval by following a link
export const canUpdate = (appPaymentProvider, subscription) => {
  if (!subscription) return false
  const { provider } = subscription
  return (
    (appPaymentProvider === 'stripe' && provider === 'stripe') ||
    (appPaymentProvider === 'apple' && provider === 'apple')
  )
}
export const canCancel = (appPaymentProvider, subscription) => {
  if (!subscription) return false
  const { provider } = subscription
  return (
    (appPaymentProvider === 'stripe' && (provider === 'stripe' || provider === 'paypal')) ||
    (appPaymentProvider === 'apple' && provider === 'apple' && !Platform.isTVOS)
  )
}

export const formatPrice = ({ currencySymbol = '$', price = 0 } = {}) =>
  `${currencySymbol}${(price / 100).toFixed(2)}`
