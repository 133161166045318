import { FormattedMessage } from 'react-intl'
import { Text, TouchableOpacity, View } from 'react-native'
import styled from 'styled-components'
import { Icon } from '../../images/Icon'

export const IconButtonContainer = styled(TouchableOpacity)`
  margin-right: ${(props) => props.marginRight || 0}px;
  margin-left: ${(props) => props.marginLeft || 0}px;
  align-items: center;
  ${(props) => !props.textDefinesWidth && 'flex: 1 0 90px'};
`

export const IconLabelWrapper = styled(View)`
  ${(props) => !props.textDefinesWidth && 'width: 100px'};
  align-items: center;
`

export const IconLabel = styled(Text).attrs({
  numberOfLines: 1,
})`
  color: ${(props) => props.theme.colors.text};
  font-size: ${(props) => props.theme.fontSizes[props.fontSize || 'xxsmall']}px;
  text-align: center;
`

export const CenteredView = styled(View)`
  align-items: center;
`

export const IconButton = ({
  title,
  intlMsg,
  onPress,
  marginLeft,
  marginRight,
  fontSize,
  textDefinesWidth = false,
  disabled,
  style,
  ...iconProps
}) => (
  <IconButtonContainer
    marginLeft={marginLeft}
    marginRight={marginRight}
    onPress={onPress}
    textDefinesWidth={textDefinesWidth}
    disabled={disabled}
    style={style}
  >
    <CenteredView pointerEvents="box-only">
      <Icon {...iconProps} />
      {(title || intlMsg) && (
        <IconLabelWrapper textDefinesWidth={textDefinesWidth}>
          <IconLabel fontSize={fontSize}>{title || <FormattedMessage {...intlMsg} />}</IconLabel>
        </IconLabelWrapper>
      )}
    </CenteredView>
  </IconButtonContainer>
)
