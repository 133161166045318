import { IconButton } from '../../../../elements/controls/IconButton/IconButton.js'
import messages from '../../messages.js'

export const ReminderButton = ({ isReminderSet, onPress, film, width, cancelMsg, ...props }) => (
  <IconButton
    {...props}
    width={width}
    name={isReminderSet ? 'alarm-on' : 'alarm'}
    intlMsg={isReminderSet ? cancelMsg ?? messages.removeReminder : messages.remindMe}
    onPress={() => onPress(film)}
  />
)
