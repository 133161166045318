import { format, formatWithOptions } from 'date-fns-tz/fp'
import enGB from 'date-fns/locale/en-GB'
import enUS from 'date-fns/locale/en-US'
import parse from 'date-fns/parse'
import parseJSON from 'date-fns/parseJSON'
import { compose } from 'lodash/fp'

const formatDateOrISO = (dateFormat) => compose(format(dateFormat), parseJSON)

export const formatDate = formatDateOrISO('yyyy-MM-dd')

export const formatTime = formatDateOrISO('HH:mm:ss')

export const formatHourMins = formatDateOrISO('HH:mm')

export const formatDateTime = formatDateOrISO('yyyy-MM-dd HH:mm')

/**
 * Tuesday Dec 2, 2020 at 3:27 PM
 */
export const formatLongLocalized = formatDateOrISO("EEEE PP 'at' p")

/**
 * Tuesday Dec 2, 2020
 */
export const formatLongLocalizedDate = formatDateOrISO('EEEE PP')

/**
 * Wed Mar 18, 2023
 */
export const formatShortLocalWeekDayDate = formatDateOrISO('E PP')

/**
 * March 18, 9:52 AM
 */
export const formatShortLocalized = formatDateOrISO('MMMM d, p')

/**
 * Mar 9, 4:07 PM
 */
export const formatVeryShortLocalized = formatDateOrISO('MMM d, p')

/**
 * Mar 9, 2021
 */
export const formatShortDateOnly = formatDateOrISO('MMM d, yyyy')

const MYSQL_FORMAT = 'yyyy-MM-dd HH:mm:ss'

export const formatMySqlDate = formatDateOrISO(MYSQL_FORMAT)
export const parseMySqlDate = (date) => parse(date, MYSQL_FORMAT, new Date())

export const formatShortTZName = (timeZone, date) =>
  formatWithOptions(
    { timeZone, locale: timeZone.startsWith('Europe') ? enGB : enUS },
    'zzz',
    parseJSON(date),
  )

// 2016-02-21
export const parseJustADate = (date) =>
  typeof date === 'string' && date.length === 10
    ? parseJSON(`${date}T00:00:00.000Z`)
    : parseJSON(date)
