import { FormattedMessage } from 'react-intl'
import { View } from 'react-native'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Image from '@flix/common/elements/images/Image/Image'
import { H1, Text } from '@flix/common/elements/typography'
import { media } from '@flix/common/style/media'
import homeMsgs from '../../../pages/landing/messages'
import messages from '../messages'

const Container = styled(View)`
  min-height: 600px;
  align-items: center;
  padding: ${(props) => props.theme.spacing.large}px ${(props) => props.theme.spacing.medium}px;

  ${media.sm`
    padding-top: ${(props) => props.theme.spacing.xxlarge}px;
  `};
`

const ImageBox = styled(View)`
  margin-bottom: ${(props) => props.theme.spacing.xlarge}px;
`

export const Fallback = styled(Text)`
  font-size: ${(props) => props.theme.fontSizes.large}px;
  line-height: ${(props) => props.theme.fontSizes.large * 1.3}px;
`

export const Error404 = ({
  item,
  hideImage,
  tryInsteadPageName,
  tryInsteadPageMsg = homeMsgs.homePage,
  tryInsteadTo = '/',
}) => (
  <Container>
    {hideImage || (
      <ImageBox>
        <Image publicId="error-404" width={240} />
      </ImageBox>
    )}

    <H1 mb="large">
      <FormattedMessage {...messages.oops404} values={{ item }} />
    </H1>
    <Fallback>
      <FormattedMessage
        {...messages.tryVisit}
        values={{
          fallbackLink: (
            <Link to={tryInsteadTo} replace style={{ textDecoration: 'underline' }}>
              {tryInsteadPageName || <FormattedMessage {...tryInsteadPageMsg} />}
            </Link>
          ),
        }}
      />
    </Fallback>
  </Container>
)

Error404.propTypes = {
  hideImage: PropTypes.bool,
  item: PropTypes.string,
}

Error404.defaultProps = {
  item: 'page',
}

export default Error404
