import { forwardRef, useMemo } from 'react'
import { FlatList, Platform, ScrollView, StyleSheet, View } from 'react-native'
import { useTheme } from 'styled-components'
import PropTypes from 'prop-types'
import {
  CATCH_UP_MORE_CHANNELS,
  DOWNLOADS_LIST,
  FESTIVAL_LINEUP,
  GROUP_WATCH,
  POSTERS_LIST,
  PROMO_PANELS_LIST,
} from '@mwxltd/flix-api-client'
import { useMediaBreakpoints } from '../../style/media'
import { DownloadsContent } from './DownloadsContent/DownloadsContent'
import { EmptyList } from './EmptyList/EmptyList'
import { FestivalLineupContent } from './FestivalLineupContent/FestivalLineupContent'
import { GroupWatchContent } from './GroupWatchContent/GroupWatchContent'
import { LoadingFooter } from './LoadingFooter/LoadingFooter'
import { PosterContent } from './PosterContent/PosterContent'
import { PosterLogoLandscapeContent } from './PosterLogoLandscapeContent/PosterLogoLandscapeContent'
import { PremieresContent } from './PremieresContent/PremieresContent.js'
import messages from './messages'

// As fast as possible
const decelerationRate = Platform.OS === 'ios' ? 0.99999 : undefined

const getContentComponent = ({ listType }) => {
  switch (listType) {
    default:
    case POSTERS_LIST:
      return PosterContent
    case PROMO_PANELS_LIST:
      return PremieresContent
    case DOWNLOADS_LIST:
      return DownloadsContent
    case GROUP_WATCH:
      return GroupWatchContent
    case FESTIVAL_LINEUP:
      return FestivalLineupContent
    case CATCH_UP_MORE_CHANNELS:
      return PosterLogoLandscapeContent
  }
}

const styles = StyleSheet.create({
  showLoader: { opacity: 1, height: 110 },
  hideLoader: { opacity: 0, height: 110 },
  rowWrap: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
})

export const ContentList = forwardRef(
  (
    {
      list = {},
      onItemChosen,
      refreshing,
      emptyMessage = messages.defaultEmpty,
      maxColumns = 3,
      maxWidth,
      keyExtractor = (item) => item.slug,
      ListFooterComponent = LoadingFooter,
      ListFooterComponentStyle,
      ListEmptyComponent,
      emptyLabel,
      premiumTitle = false,
      ...props
    },
    ref,
  ) => {
    const mediaBreakpoints = useMediaBreakpoints()

    const theme = useTheme()
    // const { width } = useDimensions()
    const listWidth = maxWidth ? Math.min(maxWidth, theme.width) : theme.width

    const { renderItem, getItemLayout, numColumns } = useMemo(() => {
      const Content = getContentComponent(list)
      const numColumns = Math.min(maxColumns, Content.getNumColumns(mediaBreakpoints))
      const panelWidth = listWidth / numColumns
      const height = Content.calcHeight ? Content.calcHeight(panelWidth, theme) : null
      return {
        renderItem: ({ item, index }) => (
          <Content
            item={item}
            index={index}
            theme={theme}
            width={panelWidth}
            onChosen={onItemChosen}
            premiumTitle={premiumTitle}
          />
        ),
        getItemLayout: height
          ? (data, index) => ({ length: height, offset: height * index, index })
          : null,
        numColumns,
      }
    }, [list, listWidth, onItemChosen, maxColumns, theme, mediaBreakpoints])

    if (refreshing && !list.listType) {
      return <ListFooterComponent />
    }

    const data = list?.items || []

    if (!refreshing && !data.length && ListEmptyComponent) {
      return (
        <ScrollView>
          <ListEmptyComponent emptyLabel={emptyLabel} />
          {ListFooterComponent !== LoadingFooter && <ListFooterComponent />}
        </ScrollView>
      )
    }

    return (
      <View style={{ width: '100%' }}>
        <FlatList
          ref={ref}
          data={data}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
          getItemLayout={getItemLayout}
          key={numColumns} // Can't change numCols on the fly, must remount
          numColumns={numColumns}
          refreshing={refreshing}
          ListEmptyComponent={<EmptyList message={emptyMessage} />}
          ListFooterComponent={ListFooterComponent}
          ListFooterComponentStyle={
            ListFooterComponentStyle ?? (refreshing ? styles.showLoader : styles.hideLoader)
          }
          decelerationRate={decelerationRate}
          {...props}
        />
      </View>
    )
  },
)

ContentList.displayName = 'ContentList'

ContentList.propTypes = {
  list: PropTypes.shape({
    listType: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
  }),
}
