import { defineMessages } from 'react-intl'

export default defineMessages({
  credits: {
    id: 'app.filmDetails.credits',
    defaultMessage: 'Credits',
  },
  media: {
    id: 'app.filmDetails.media',
    defaultMessage: 'Media',
  },
  nfts: {
    id: 'app.filmDetails.nfts',
    defaultMessage: 'NFTs',
  },
  viewMore: {
    id: 'app.filmDetails.viewMore',
    defaultMessage: 'View more',
  },
  moreLikeThis: {
    id: 'app.filmDetails.moreLikeThis',
    defaultMessage: 'More Like This',
  },
  episodes: {
    id: 'app.filmDetails.episodes',
    defaultMessage: 'Episodes',
  },
  moreChannels: {
    id: 'app.catchUpDetails.moreChannels',
    defaultMessage: 'More Channels',
  },
})
